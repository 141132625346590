import React, { Component } from 'react'

export default class TodaysSpecial extends Component {
    render() {
        return (
            <>
                <div className="advertisement-slider swiper-container h-auto mb-xl-20 mb-md-40">
                    <div className="swiper-wrapper">
                        <div className="swiper-slide">
                            <div className="testimonial-wrapper">
                                <div className="testimonial-box">
                                    <div className="testimonial-img p-relative">
                                        <a href="restaurant.html">
                                            <img src="https://via.placeholder.com/438x180" className="img-fluid full-width" alt="testimonial-img" />
                                        </a>
                                        <div className="overlay">
                                            <div className="brand-logo">
                                                <img src="https://via.placeholder.com/50" className="img-fluid" alt="logo" />
                                            </div>
                                            <div className="add-fav text-success">
                                                {/* <img src="/assets/img/svg/013-heart-1.svg" alt="tag" /> */ }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonial-caption padding-15">
                                        <p className="text-light-white text-uppercase no-margin fs-12">Featured</p>
                                        <h5 className="fw-600"><a href="restaurant.html" className="text-light-black">GSA King Tomato Farm</a></h5>
                                        <div className="testimonial-user-box">
                                            <img src="https://via.placeholder.com/40" className="rounded-circle" alt="#" />
                                            <div className="testimonial-user-name">
                                                <p className="text-light-black fw-600">Sarra</p> <i className="fas fa-trophy text-black" /><span className="text-light-black">Top Reviewer</span>
                                            </div>
                                        </div>
                                        <div className="ratings"> <span className="text-yellow fs-16">
                                            <i className="fas fa-star" />
                                        </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                        </div>
                                        <p className="text-light-black">Delivery was fast and friendly...</p>
                                        <p className="text-light-white fw-100"><strong className="text-light-black fw-700">Local delivery: </strong> From $7.99 (4.0 mi)</p>
                                        <a href="checkout.html" className="btn-second btn-submit">Order Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="testimonial-wrapper">
                                <div className="testimonial-box">
                                    <div className="testimonial-img p-relative">
                                        <a href="restaurant.html">
                                            <img src="https://via.placeholder.com/438x180" className="img-fluid full-width" alt="testimonial-img" />
                                        </a>
                                        <div className="overlay">
                                            <div className="brand-logo">
                                                <img src="https://via.placeholder.com/50" className="img-fluid" alt="logo" />
                                            </div>
                                            {/* <div className="add-fav text-success"><img src="/assets/img/svg/013-heart-1.svg" alt="tag" />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="testimonial-caption padding-15">
                                        <p className="text-light-white text-uppercase no-margin fs-12">Featured</p>
                                        <h5 className="fw-600"><a href="restaurant.html" className="text-light-black">GSA King Tomato Farm</a></h5>
                                        <div className="testimonial-user-box">
                                            <img src="https://via.placeholder.com/40" className="rounded-circle" alt="#" />
                                            <div className="testimonial-user-name">
                                                <p className="text-light-black fw-600">Sarra</p> <i className="fas fa-trophy text-black" /><span className="text-light-black">Top Reviewer</span>
                                            </div>
                                        </div>
                                        <div className="ratings"> <span className="text-yellow fs-16">
                                            <i className="fas fa-star" />
                                        </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                        </div>
                                        <p className="text-light-black">Delivery was fast and friendly...</p>
                                        <p className="text-light-white fw-100"><strong className="text-light-black fw-700">Local delivery: </strong> From $7.99 (4.0 mi)</p>
                                        <a href="checkout.html" className="btn-second btn-submit">Order Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="testimonial-wrapper">
                                <div className="testimonial-box">
                                    <div className="testimonial-img p-relative">
                                        <a href="restaurant.html">
                                            <img src="https://via.placeholder.com/438x180" className="img-fluid full-width" alt="testimonial-img" />
                                        </a>
                                        <div className="overlay">
                                            <div className="brand-logo">
                                                <img src="https://via.placeholder.com/50" className="img-fluid" alt="logo" />
                                            </div>
                                            {/* <div className="add-fav text-success"><img src="/assets/img/svg/013-heart-1.svg" alt="tag" />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="testimonial-caption padding-15">
                                        <p className="text-light-white text-uppercase no-margin fs-12">Featured</p>
                                        <h5 className="fw-600"><a href="restaurant.html" className="text-light-black">GSA King Tomato Farm</a></h5>
                                        <div className="testimonial-user-box">
                                            <img src="https://via.placeholder.com/40" className="rounded-circle" alt="#" />
                                            <div className="testimonial-user-name">
                                                <p className="text-light-black fw-600">Sarra</p> <i className="fas fa-trophy text-black" /><span className="text-light-black">Top Reviewer</span>
                                            </div>
                                        </div>
                                        <div className="ratings"> <span className="text-yellow fs-16">
                                            <i className="fas fa-star" />
                                        </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                        </div>
                                        <p className="text-light-black">Delivery was fast and friendly...</p>
                                        <p className="text-light-white fw-100"><strong className="text-light-black fw-700">Local delivery: </strong> From $7.99 (4.0 mi)</p>
                                        <a href="checkout.html" className="btn-second btn-submit">Order Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add Arrows */ }
                    <div className="swiper-button-next" />
                    <div className="swiper-button-prev" />
                </div>
                <div className="card sidebar-card">
                </div>
            </>
        )
    }
}
