import React, { Component } from 'react'
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';

const InjectedCheckoutForm = () => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <CheckoutForm stripe={ stripe } elements={ elements } />
        ) }
    </ElementsConsumer>
);

export default class demo extends Component {

    render() {

        const key = (window.location.origin.includes("localhost") || window.location.origin.includes("harshpatel.in")) ? "pk_test_51I1DKZHHog7z8fbT30T2rbAM0HvtYJZ7vJskUD1Pyq4iYQ3EE8WJGx4J4xweBPIIc1ROzP8OUJaPRa0iHJItIge700Mb8RYvtT" : "pk_live_51I1DKZHHog7z8fbT5QZemMY4ksbtnjuXvPPXBwvfmIWECReOiGvEyxYsFRw23FYTHaen5aTHtj8XlLfhLKWqcJum00NMKjVuEX";
        const stripePromise = loadStripe(key);

        return (
            <div>
                <Elements stripe={ stripePromise }>
                    <InjectedCheckoutForm />
                </Elements>
            </div>
        )
    }
}
