import React, { Component } from 'react'
import Footer from '../Footer'
import Header from '../Header'
import { GetSessionData } from '../../GlobalFactory';
import ApiInstance from '../../Utilities/axiosConfig';
import Config from '../../Config';
import TodaysSpecial from './TodaysSpecial';
import Cart from '../CheckOut/Cart';

export default class index extends Component {

    constructor (props) {
        super(props)
        this.id = this.props.match.params.id;
        this.state = {
            RestarentData: {},
            DishList: [],
            TodaysSpecDish: [],
            WeekSpecDish: [],
            PhotoGallery: [],
            DishData: {},
            DishId: '0',
            DishOrder: {
                DishId: "",
                CookId: "",
                DishName: "",
                CuisineId: "",
                CuisineName: "",
                FoodTypeId: 0,
                FoodTypeName: "",
                FoodMealTypeId: 0,
                FoodMealTypeName: "",
                FoodCategoryTypeId: 0,
                FoodCategoryTypeName: "",
                AllergensId: 0,
                AllergensName: "",
                Photo: "",
                TotalCalories: 0,
                DishVariantId: '',
                VariantName: "",
                AddOnsArray: [],
                AddOnsTotal: 0,
                Price: 0,
                Discount: 0,
                PackingCharges: 0,
                FinalPrice: 0,
                Quantity: 0,
                QuantityType: "",
                BuyQuantity: 0,
                FinalTotalPerQuantity: 0,
                FinalTotalWithAddOns: 0,
            },
            CartOrder: {
                CustomerId: 1,
                CookId: this.id,
                TotalQty: 0,
                TotalAmount: 0,
                PaymnetMode: "Credit Card",
                PaymentStatus: "Success",
                TransactionNumber: "",
                OrderStatusId: 1,
                ExtraNotes: "",
                ProcessingCharge: 5,
                Tip: 0,
                Grandtotal: 0,
                tblOrderChilds:
                    [
                        // {
                        //     DishId: "95",
                        //     CookId: "70",
                        //     DishName: "South Inidian",
                        //     CuisineId: "114",
                        //     CuisineName: "Cuisine name",
                        //     FoodTypeId: 164,
                        //     FoodTypeName: "Food Type NAme",
                        //     FoodMealTypeId: 5,
                        //     FoodMealTypeName: "Food meal type name",
                        //     FoodCategoryTypeId: 21,
                        //     FoodCategoryTypeName: "food category id",
                        //     AllergensId: 11,
                        //     AllergensName: "allergence name",
                        //     Photo: "/Resources/CookDishPhotos/70_637406652205624664.jpg",
                        //     TotalCalories: 1500,
                        //     DishVariantId: 161,
                        //     VariantName: "Double",
                        //     AddOnsArray: "[]",
                        //     AddOnsTotal: 10,
                        //     Price: 13,
                        //     Discount: 1,
                        //     PackingCharges: 1,
                        //     FinalPrice: 13.87,
                        //     Quantity: "1",
                        //     QuantityType: "PKG",
                        //     BuyQuantity: 2,
                        //     FinalTotalPerQuantity: 27,
                        //     FinalTotalWithAddOns: 37,
                        // }
                    ]
            }
        }

    }

    componentDidMount() {
        this.GetRestaurentData();
        this.GetRatingReview();
        // this.GetTodaysSpecialData();
        //    this.GetWeekSpecialData();
        //localStorage.setItem("CartOrder", '')
        let CartOrder = localStorage.getItem("CartOrder")

        if (CartOrder) {
            this.setState({ CartOrder: JSON.parse(CartOrder) })
        }
    }

    GetRestaurentData() {

        ApiInstance().get('CustomerHome/SelectCookProfileAndDishListByCookId?CookId=' + this.id)
            .then(response => {
                var data = response.data.data;
                //console.log(data)
                if (data) {
                    let objdata = JSON.parse(data).Data
                    let DishList = JSON.parse(objdata[0].DishList)
                    let TodaysSpecial = JSON.parse(objdata[0].TodaysSpecial)
                    let WeekSpecial = JSON.parse(objdata[0].WeekSpecial)
                    let PhotoGallery = JSON.parse(objdata[0].tblPhotoGalleryMaster)
                    //console.log(objdata)
                    this.setState({
                        RestarentData: objdata[0], DishList: DishList, TodaysSpecDish: TodaysSpecial,
                        WeekSpecDish: WeekSpecial, PhotoGallery: PhotoGallery
                    });
                }
            }, error => {
                console.log(error);
            });


    }

    GetTodaysSpecialData() {

        ApiInstance().get('TodaysSpecial/SelectTodayWeekSpecialByCookId?CookId=' + this.id)
            .then(response => {
                var data = response.data.data;

                if (data) {
                    let objdata = JSON.parse(data).Data
                    //console.log(objdata)
                    if (objdata.length > 0) {
                        let TodaysSpecDish = (objdata) ? JSON.parse(objdata[0].DishList) : [];
                        this.setState({
                            TodaysSpecDish: TodaysSpecDish
                        });
                    }
                }
            }, error => {
                console.log(error);
            });


    }


    FilterDishList = (searchtext = '') => {
        if (searchtext !== '') {
            var DishList = this.state.DishList;
            var filteredDishList = DishList.filter(predicate => predicate.Keyword.includes(searchtext))
            this.setState({ DishList: filteredDishList })
        }

    }

    LoadTodaysSpecialDishList() {
        return (
            <>
                { this.state.TodaysSpecDish.map((item, index) =>
                    <div className="col-lg-12" key={ index }>
                        <div className="restaurent-product-list overflow-hidden position-relative">
                            { !item.IsInStock && <div className="overlay-div-dish">
                            </div>
                            }

                            <div className="restaurent-product-detail" data-toggle="modal" data-target="#restaurent-popup" onClick={ () => { this.getDish(item.DishId) } }>
                                <div className="restaurent-product-left">
                                    <div className="restaurent-product-title-box">
                                        <div className="restaurent-product-box">
                                            <div className="restaurent-product-title">
                                                <h6 className="mb-2" data-toggle="modal" data-target="#restaurent-popup"><a href="#" className="text-light-black fw-600" onClick={ () => { this.getDish(item.DishId) } }>{ item.DishName }</a></h6>
                                                <p className="text-light-white">{ item.FoodTypeName }</p>
                                                <p className="text-light-white">{ (item.TotalCalories) ? (item.TotalCalories + " Cal.") : "" }</p>
                                            </div>
                                            {/* <div className="restaurent-product-label"> <span className="rectangle-tag bg-gradient-red text-custom-white">Label</span>
                                                <span className="rectangle-tag bg-dark text-custom-white">Combo</span>
                                            </div> */}
                                        </div>
                                        <div className="restaurent-product-rating">
                                            <div className="ratings">
                                                {/* <span className="text-yellow"><i className="fas fa-star" /></span>
                                                <span className="text-yellow"><i className="fas fa-star" /></span>
                                                <span className="text-yellow"><i className="fas fa-star" /></span>
                                                <span className="text-yellow"><i className="fas fa-star" /></span>
                                                <span className="text-yellow"><i className="fas fa-star-half-alt" /></span> */}
                                            </div>
                                            <div className="rating-text">
                                                {/* <p className="text-light-white fs-12 title">3845 ratings</p> */ }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="restaurent-product-caption-box"> <span className="text-light-white">{ item.Description }</span>
                                    </div>
                                    <div className="restaurent-tags-price">
                                        <div className="restaurent-tags">
                                            {/* <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/004-leaf.svg" alt="tag" />
                                            </span>
                                            <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/006-chili.svg" alt="tag" />
                                            </span>
                                            <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/005-chef.svg" alt="tag" />
                                            </span>
                                            <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/008-protein.svg" alt="tag" />
                                            </span>
                                            <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/009-lemon.svg" alt="tag" />
                                            </span> */}
                                        </div>
                                        {/* <span className="circle-tag">
                                             <img src="/assets/img/svg/010-heart.svg" alt="tag" /> 
                                        </span> */}
                                        <div className="restaurent-product-price">
                                            <h6 className="text-success fw-600 no-margin">${ item.FinalPrice } </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="restaurent-product-img">
                                    <img src={ (item.Photo.length > 3) ? (Config.BaseURL + item.Photo) : "https://via.placeholder.com/150x151" } className="img-fluid" alt="#" />
                                </div>
                            </div>
                        </div>
                    </div>

                ) }
            </>
        )
    }
    LoadWeekSpecialDishList() {
        return (
            <>
                { this.state.WeekSpecDish.map((item, index) =>
                    <div className="col-lg-12" key={ index }>
                        <div className="restaurent-product-list overflow-hidden position-relative">
                            { !item.IsInStock && <div className="overlay-div-dish">
                            </div>
                            }
                            <div className="restaurent-product-detail" data-toggle="modal" data-target="#restaurent-popup" onClick={ () => { this.getDish(item.DishId) } }>
                                <div className="restaurent-product-left">
                                    <div className="restaurent-product-title-box">
                                        <div className="restaurent-product-box">
                                            <div className="restaurent-product-title">
                                                <h6 className="mb-2" data-toggle="modal" data-target="#restaurent-popup"><a href="#" className="text-light-black fw-600" onClick={ () => { this.getDish(item.DishId) } }>{ item.DishName }</a></h6>
                                                <p className="text-light-white">{ item.FoodTypeName }</p>
                                                <p className="text-light-white">{ (item.TotalCalories) ? (item.TotalCalories + " Cal.") : "" }</p>
                                            </div>
                                            {/* <div className="restaurent-product-label"> <span className="rectangle-tag bg-gradient-red text-custom-white">Label</span>
                                                <span className="rectangle-tag bg-dark text-custom-white">Combo</span>
                                            </div> */}
                                        </div>
                                        <div className="restaurent-product-rating">
                                            <div className="ratings">
                                                {/*                                                 
                                                <span className="text-yellow"><i className="fas fa-star" /></span>
                                                <span className="text-yellow"><i className="fas fa-star" /></span>
                                                <span className="text-yellow"><i className="fas fa-star" /></span>
                                                <span className="text-yellow"><i className="fas fa-star" /></span>
                                                <span className="text-yellow"><i className="fas fa-star-half-alt" /></span> */}
                                            </div>
                                            <div className="rating-text">
                                                {/* <p className="text-light-white fs-12 title">3845 ratings</p> */ }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="restaurent-product-caption-box"> <span className="text-light-white">{ item.Description }</span>
                                    </div>
                                    <div className="restaurent-tags-price">
                                        <div className="restaurent-tags">
                                            {/* <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/004-leaf.svg" alt="tag" />
                                            </span>
                                            <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/006-chili.svg" alt="tag" />
                                            </span>
                                            <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/005-chef.svg" alt="tag" />
                                            </span>
                                            <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/008-protein.svg" alt="tag" />
                                            </span>
                                            <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/009-lemon.svg" alt="tag" />
                                            </span> */}
                                        </div>
                                        {/* <span className="circle-tag">
                                            <img src="/assets/img/svg/010-heart.svg" alt="tag" /> 
                                        </span> */}
                                        <div className="restaurent-product-price">
                                            <h6 className="text-success fw-600 no-margin">${ item.FinalPrice } </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="restaurent-product-img">
                                    <img src={ (item.Photo.length > 3) ? (Config.BaseURL + item.Photo) : "https://via.placeholder.com/150x151" } className="img-fluid" alt="#" />
                                </div>
                            </div>
                        </div>
                    </div>

                ) }
            </>
        )
    }
    LoadDishiesList() {
        console.log(this.state.DishList)
        return (
            <>
                { this.state.DishList.map((item, index) =>
                    <div className="col-lg-12" key={ index }>
                        <div className="restaurent-product-list overflow-hidden position-relative">
                            { !item.IsInStock && <div className="overlay-div-dish">
                            </div>
                            }
                            <div className="restaurent-product-detail" data-toggle="modal" data-target="#restaurent-popup" onClick={ () => { this.getDish(item.DishId) } }>
                                <div className="restaurent-product-left">
                                    <div className="restaurent-product-title-box">
                                        <div className="restaurent-product-box">
                                            <div className="restaurent-product-title">
                                                <h6 className="mb-2" data-toggle="modal" data-target="#restaurent-popup"><a href="#" className="text-light-black fw-600" onClick={ () => { this.getDish(item.DishId) } }>{ item.DishName }</a></h6>
                                                <p className="text-light-white">{ item.FoodTypeName }</p>
                                                <p className="text-light-white">{ (item.TotalCalories) ? (item.TotalCalories + " Cal.") : "" }</p>
                                            </div>
                                            {/* <div className="restaurent-product-label"> <span className="rectangle-tag bg-gradient-red text-custom-white">Label</span>
                                                <span className="rectangle-tag bg-dark text-custom-white">Combo</span>
                                            </div> */}
                                        </div>
                                        <div className="restaurent-product-rating">
                                            <div className="ratings">
                                                {/* <span className="text-yellow"><i className="fas fa-star" /></span>
                                                <span className="text-yellow"><i className="fas fa-star" /></span>
                                                <span className="text-yellow"><i className="fas fa-star" /></span>
                                                <span className="text-yellow"><i className="fas fa-star" /></span>
                                                <span className="text-yellow"><i className="fas fa-star-half-alt" /></span> */}
                                            </div>
                                            <div className="rating-text">
                                                {/* <p className="text-light-white fs-12 title">3845 ratings</p> */ }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="restaurent-product-caption-box"> <span className="text-light-white">{ item.Description }</span>
                                    </div>
                                    <div className="restaurent-tags-price">
                                        <div className="restaurent-tags">
                                            {/* <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/004-leaf.svg" alt="tag" />
                                            </span>
                                            <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/006-chili.svg" alt="tag" />
                                            </span>
                                            <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/005-chef.svg" alt="tag" />
                                            </span>
                                            <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/008-protein.svg" alt="tag" />
                                            </span>
                                            <span className="text-custom-white square-tag">
                                                <img src="/assets/img/svg/009-lemon.svg" alt="tag" />
                                            </span> */}
                                        </div>
                                        {/* <span className="circle-tag">
                                            <img src="/assets/img/svg/010-heart.svg" alt="tag" /> 
                                        </span>  */}
                                        <div className="restaurent-product-price">
                                            <h6 className="text-success fw-600 no-margin">${ item.FinalPrice } </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="restaurent-product-img">
                                    <img src={ (item.Photo.length > 3) ? (Config.BaseURL + item.Photo) : "https://via.placeholder.com/150x151" } className="img-fluid" alt="#" />
                                </div>
                            </div>
                        </div>
                    </div>

                ) }
            </>
        )
    }

    getDish(DishId) {
        ApiInstance().get('CustomerHome/SelectDishMasterWithAddOnsAndVarientsByDishId?DishId=' + DishId)
            .then(response => {
                var data = response.data.data;

                if (data) {
                    let objdata = JSON.parse(data).Data
                    console.log(objdata[0])
                    this.setState({
                        DishData: objdata[0]
                    });
                }
            }, error => {
                console.log(error);
            });
    }

    DishNutritionsTable(nutritionobj = '[]') {

        let nutritionobjArr = JSON.parse(nutritionobj)


        return (
            <p className="text-light-black no-margin">
                Nutritions:
                {
                    nutritionobjArr.map((element, index) => <><br />{ ' ' + element.NutritionName + ' - ' + element.InitialValue + ' ' + element.Measurement }</>)
                }
            </p>
        )
    }

    DishNutritionsTable_V1(nutritionobj = '[]') {

        let nutritionobjArr = JSON.parse(nutritionobj)

        // console.log(nutritionobjArr)
        return (
            <>
                {
                    nutritionobjArr.map((element, index) => <>
                        {/* <br />{ ' ' + element.NutritionName + ' - ' + element.InitialValue + ' ' + element.Measurement } */ }
                        {
                            element.InitialValue && <div className="col-lg-6 col-6 text-center pb-2 pl-1 pr-1">
                                <div className="border bg-light form-inlin p-1">
                                    <p className=" mb-0 d-inline"><b>{ element.NutritionName }</b></p> <p className="ml-1 mr-1 d-inline">-</p> <p className=" mb-0 d-inline">{ element.InitialValue + '' + element.Measurement }</p>
                                </div>
                            </div>
                        }
                    </>)
                }
            </>
        )
    }

    AddonClickEvent(e, obj) {


        let DishOrder = this.state.DishOrder;
        console.log(DishOrder)
        console.log(obj)
        if (!this.ValidateDishOrder(DishOrder)) return;

        let ifexist = DishOrder.AddOnsArray.find(ele => ele.DishAddOnsId == obj.DishAddOnsId)
        if (ifexist) {
            let tempaddonarr = DishOrder.AddOnsArray.filter(ele => ele.DishAddOnsId != obj.DishAddOnsId)
            DishOrder.AddOnsArray = tempaddonarr;
            // DishOrder.AddOnsTotal = parseFloat(DishOrder.AddOnsTotal) - (parseFloat(DishOrder.BuyQuantity) * parseFloat(obj.AddOnsCharges));
            document.getElementById(e.target.id).textContent = "+";
        }
        else {

            // DishOrder.AddOnsTotal = parseFloat(DishOrder.AddOnsTotal) + (parseFloat(DishOrder.BuyQuantity) * parseFloat(obj.AddOnsCharges));
            document.getElementById(e.target.id).textContent = "-";
            DishOrder.AddOnsArray.push(obj)

        }


        //console.log(DishOrder)
        //DishOrder.FinalPrice = DishOrder.FinalPrice + DishOrder.AddOnsTotal;
        //this.setState({ DishOrder: DishOrder })
        //console.log(this.state.DishOrder)
        this.GenerateJson(DishOrder)

    }

    AddOnList(addOnList = '[]') {
        addOnList = JSON.parse(addOnList)
        //console.log(addOnList)
        return (<>

            {  addOnList.map((element, index) =>
                <button className="add-pro" key={ index } id={ 'addonId' + element.DishAddOnsId } onClick={ (event) => { this.AddonClickEvent(event, element) } } > { element.AddOnsName + '(' + element.FoodTypeName + ')' }
                    <span>+${ element.AddOnsCharges }</span><span className="close" name="AddonSign" id={ 'AddonSign' + element.DishAddOnsId }>+</span>
                </button>
            )
            }
        </>
        )
    }

    CalculateQuantity(event, c) {

        // DishOrder: {
        //     TotalAmount: 0,
        //         AddOnsArray: [],
        //             OrderedDishList: [],

        //     }

        let qty = parseInt(document.getElementById('txtQtyR').value);

        if (c === '+') {
            qty = qty + 1
        }

        if (c === '-') {
            if ((qty - 1) < 0) {
                qty = 0;
            }
            else {
                qty = qty - 1
            }
        }
        document.getElementById('txtQtyR').value = qty;

        let DishOrder = this.state.DishOrder;
        DishOrder.BuyQuantity = qty;
        this.GenerateJson(DishOrder);

    }


    GenerateJson(DishOrder) {

        //let qty = document.getElementById('txtQtyR').value;

        let DishData = this.state.DishData;

        if (!this.ValidateDishOrder(DishOrder)) return;

        DishOrder.DishId = DishData.DishId;
        DishOrder.CookId = this.id;
        DishOrder.DishName = DishData.DishName;
        DishOrder.CuisineId = DishData.CuisineId;
        DishOrder.CuisineName = DishData.CuisineName;
        DishOrder.FoodTypeId = DishData.FoodTypeId;
        DishOrder.FoodTypeName = DishData.FoodTypeName;
        DishOrder.FoodMealTypeId = DishData.FoodMealTypeId;
        DishOrder.FoodMealTypeName = DishData.FoodMealTypeName;
        DishOrder.FoodCategoryTypeId = DishData.FoodCategoryTypeId;
        DishOrder.FoodCategoryTypeName = DishData.FoodCategoryTypeName;
        DishOrder.AllergensId = DishData.AllergensId;
        DishOrder.AllergensName = DishData.AllergensName;
        DishOrder.Photo = DishData.Photo;
        DishOrder.TotalCalories = DishData.TotalCalories;

        // DishOrder.AddOnsArray = [];
        DishOrder.AddOnsTotal = 0;
        //parseFloat(number).toPrecision(12)
        DishOrder.Quantity = parseInt(DishData.Quantity);
        //DishOrder.BuyQuantity = qty;

        //  console.log(object)
        // console.log(object)


        //////Adon recalculate//////////
        // let tempaddonarr = DishOrder.AddOnsArray.filter(ele => ele.DishAddOnsId != obj.DishAddOnsId)
        //  DishOrder.AddOnsArray = tempaddonarr;

        DishOrder.AddOnsArray.map((obj) => {
            DishOrder.AddOnsTotal = parseFloat(DishOrder.AddOnsTotal) + (parseFloat(DishOrder.BuyQuantity) * parseFloat(obj.AddOnsCharges));
        })


        //////addon recalculate End//////////


        DishOrder.FinalPrice =
            parseFloat(DishOrder.Price)
            + ((DishOrder.PackingCharges) ? (parseFloat(DishOrder.PackingCharges)) : parseFloat(0))
            - parseFloat(((parseFloat(DishOrder.Price)) * ((parseFloat(DishOrder.Discount)) ? (parseFloat(DishOrder.Discount)) : parseFloat(0))) / 100)


        DishOrder.FinalTotalPerQuantity = (parseFloat(DishOrder.BuyQuantity) * parseFloat(DishOrder.FinalPrice));
        DishOrder.FinalTotalWithAddOns = parseFloat(parseFloat(DishOrder.FinalTotalPerQuantity) + parseFloat(DishOrder.AddOnsTotal)).toFixed(2);


        // console.log(DishOrder)
        this.setState({ DishOrder: DishOrder })
    }

    SetDishOrderVarient(varientobj) {

        let DishData = this.state.DishData;
        let DishOrder = this.state.DishOrder;

        if (varientobj) {

            DishOrder.DishVariantId = varientobj.DishVariantId;
            DishOrder.QuantityType = (varientobj.QuantityType) ? varientobj.QuantityType : ''
            DishOrder.Price = parseInt(varientobj.Price);
            DishOrder.VariantName = varientobj.VariantName;
            DishOrder.Discount = parseInt((varientobj.Discount) ? varientobj.Discount : 0);
            DishOrder.PackingCharges = parseInt((varientobj.PackingCharges) ? varientobj.PackingCharges : 0);

        }
        else {
            DishOrder.DishVariantId = 0;
            DishOrder.QuantityType = (DishData.QuantityType) ? DishData.QuantityType : '';
            DishOrder.Price = (DishData.Price) ? DishData.Price : 0;
            DishOrder.VariantName = (DishData.VariantName) ? DishData.VariantName : '';
            DishOrder.Discount = (DishData.Discount) ? DishData.Discount : 0;
            DishOrder.PackingCharges = (DishData.PackingCharges) ? DishData.PackingCharges : 0;

        }
        document.getElementById("rdpVarient" + DishOrder.DishVariantId).checked = true;

        this.GenerateJson(DishOrder);

    }

    QuantityList(dishVarient = '[]') {
        let dishVareintList = JSON.parse(dishVarient)

        return (
            <>
                <button onClick={ (e) => { this.SetDishOrderVarient(undefined) } } className="add-pro" style={ { width: "100%", textAlign: "left" } } key={ index } id={ 'btnVarient' + this.state.DishData.DishId }  >

                    <input type="radio" key={ index } name="rdpVarient" id={ "rdpVarient0" } /> &nbsp;&nbsp;&nbsp;
                    <span style={ { textAlign: "left" } }>Regular</span>
                    <span style={ { textAlign: "right", float: "right" } }>
                        Save { (this.state.DishData.Discount || '0') + '%' }
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                Packing Charge { '$' + (this.state.DishData.PackingCharges) }
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                { '$' + this.state.DishData.FinalPrice + '/' + this.state.DishData.QuantityType }
                    </span>
                </button> <br />

                { dishVareintList.map((item, index) =>
                    <>
                        {/* <option value={ item.DishVariantId }>{ item.VariantName + '   $' + item.Price + '/' + item.QuantityType }</option> */ }
                        <button onClick={ (e) => { this.SetDishOrderVarient(item) } } className="add-pro" style={ { width: "100%", textAlign: "left" } } key={ index } id={ 'btnVarient' + item.DishVariantId }  >

                            <input type="radio" key={ index } name="rdpVarient" id={ "rdpVarient" + item.DishVariantId } /> &nbsp;&nbsp;&nbsp;
                            <span style={ { textAlign: "left" } }>{ item.VariantName }</span>
                            <span style={ { textAlign: "right", float: "right" } }>
                                Save { (item.Discount || '0') + '%' }
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                Packing Charge { '$' + (item.PackingCharges) }
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                { '$' + item.FinalPrice + '/' + item.QuantityType }
                            </span>
                        </button> <br />
                    </>
                ) }

            </>
        )

    }


    AddToCart() {
        let DishOrder = this.state.DishOrder;
        if (this.ValidateDishOrder(DishOrder)) {

            // let CartOrder = this.state.CartOrder;
            let CartOrder = (localStorage.getItem("CartOrder")) ? JSON.parse(localStorage.getItem("CartOrder")) : this.state.CartOrder;


            DishOrder.AddOnsArray = JSON.stringify(DishOrder.AddOnsArray);
            DishOrder.UID = Date.now();
            CartOrder.tblOrderChilds.push(DishOrder);

            let tblOrderChilds = CartOrder.tblOrderChilds;
            let TotalAmount = 0;
            let TotalQty = 0
            tblOrderChilds.map((item, index) => {
                TotalAmount = parseFloat(TotalAmount) + parseFloat(item.FinalTotalWithAddOns);
                TotalQty = parseInt(TotalQty) + parseInt(item.BuyQuantity);
            })
            CartOrder.TotalAmount = TotalAmount;
            CartOrder.TotalQty = TotalQty;
            CartOrder.ProcessingCharge = 5;
            CartOrder.Tip = 0;
            CartOrder.Grandtotal = CartOrder.TotalAmount + CartOrder.ProcessingCharge + CartOrder.Tip;


            localStorage.setItem("CartOrder", JSON.stringify(CartOrder))
            this.setState({ CartOrder: CartOrder })
            this.ResetOrderData();
            //            console.log("Add To Cart")
            //          console.log(CartOrder)
            //        var timeStampInMs = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
            //      console.log(timeStampInMs, Date.now());
        }
    }

    ValidateDishOrder(DishOrder) {

        if (DishOrder.BuyQuantity == '0') {
            document.getElementById("quantityValidationError").style.display = 'block';
            return false;
        }
        else if (DishOrder.DishVariantId === '') {
            if (DishOrder.BuyQuantity > 0) {
                document.getElementById("quantityValidationError").style.display = 'none';
            }
            document.getElementById("varientValidationError").style.display = 'block';
            return false;
        }
        else {
            document.getElementById("varientValidationError").style.display = 'none';
            document.getElementById("quantityValidationError").style.display = 'none';
        }



        return true;

    }

    ResetOrderData() {
        let resetDishOrder = {
            DishId: "",
            CookId: "",
            DishName: "",
            CuisineId: "",
            CuisineName: "",
            FoodTypeId: 0,
            FoodTypeName: "",
            FoodMealTypeId: 0,
            FoodMealTypeName: "",
            FoodCategoryTypeId: 0,
            FoodCategoryTypeName: "",
            AllergensId: 0,
            AllergensName: "",
            Photo: "",
            TotalCalories: 0,
            DishVariantId: '',
            VariantName: "",
            AddOnsArray: [],
            AddOnsTotal: 0,
            Price: 0,
            Discount: 0,
            PackingCharges: 0,
            FinalPrice: 0,
            Quantity: 0,
            QuantityType: "",
            BuyQuantity: 0,
            FinalTotalPerQuantity: 0,
            FinalTotalWithAddOns: 0,
        }

        this.setState({ DishOrder: resetDishOrder })
        document.getElementById("closemodel").click();
        document.getElementById('txtQtyR').value = 0;

        document.getElementById("varientValidationError").style.display = 'none';
        document.getElementById("quantityValidationError").style.display = 'none';


        var ele = document.getElementsByName("rdpVarient");
        for (var i = 0; i < ele.length; i++)
            ele[i].checked = false;


        var eleAddon = document.getElementsByName("AddonSign");
        for (var i = 0; i < eleAddon.length; i++)
            eleAddon[i].textContent = "+";
    }

    ModelPopupItemList() {

        return (
            <>
                {/* product popup */ }
                <div className="modal fade restaurent-popup" id="restaurent-popup" data-keyboard="false" data-backdrop="static">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" id="closemodel" data-dismiss="modal">×</button>
                                <img src={ ((this.state.DishData.Photo) ? (this.state.DishData.Photo.length > 3) : false) ? (Config.BaseURL + this.state.DishData.Photo) : "https://via.placeholder.com/800x200" } className="img-fluid full-width" alt="#" />
                            </div>
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="name padding-10">
                                                <div>
                                                    <h3 className="text-light-black fw-700 mb-2">{ this.state.DishData.DishName }</h3>

                                                    <h5 className="text-light-black fw-600 no-margin">$ { this.state.DishData.FinalPrice }</h5>
                                                </div>

                                            </div>
                                            <div className="product-quantity padding-10 ml-auto">
                                                <div className="input-group quantity">
                                                    <div className="input-group-append">
                                                        <button className="minus-btn" type="button" name="button" onClick={ (e) => this.CalculateQuantity(e, '-') }> <i className="fas fa-minus" />
                                                        </button>
                                                    </div>
                                                    <input type="text" className="text-center" id="txtQtyR" name="txtQtyR" defaultValue='0' min="0" />
                                                    <div className="input-group-prepend">
                                                        <button className="plus-btn" type="button" name="button" onClick={ (e) => this.CalculateQuantity(e, '+') }><i className="fas fa-plus" />
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                {/* <div className="padding-10">
                                    <p className="text-light-black no-margin">{ 'Cuisine : ' + this.state.DishData.CuisineName }</p>
                                    <p className="text-light-black no-margin">{ 'Food Type :' + this.state.DishData.FoodTypeName }</p>
                                    <p className="text-light-black no-margin">{ this.state.DishData.Description }</p><br />
                                    { this.DishNutritionsTable(this.state.DishData.Nutritions) }

                                </div> */}

                                <div className="padding-10 ">

                                    <div className='container-fluid'>
                                        <div className="row">
                                            <div className="col-lg-5 col-12 mb-1">
                                                <div className="col-lg-12 col-12 mb-3 p-0">
                                                    <div className="mb-2">
                                                        <h5>
                                                            Cuisine
                                                         </h5>
                                                    </div>
                                                    <div className="border bg-light">
                                                        <p className="p-2 mb-0">{ this.state.DishData.CuisineName }</p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 col-12 mb-3 p-0">
                                                    <div className="mb-2">
                                                        <h5>
                                                            Food Type
                                                        </h5>
                                                    </div>
                                                    <div className="border bg-light">
                                                        <p className="p-2 mb-0">{ this.state.DishData.FoodTypeName }</p>
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="col-lg-7 col-12 mb-1">
                                                <div className="col-lg-12 col-12 mb-3 p-0">
                                                    <div className="mb-2">
                                                        <h5>
                                                            Nutritions
                                                          </h5>
                                                    </div>
                                                    <div className="container-fluid">
                                                        <div className="row h-control p-2 border">
                                                            { this.DishNutritionsTable_V1(this.state.DishData.Nutritions) }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-12 p-0">
                                                <div className="border bg-light">
                                                    <p className="p-2 mb-0">{ this.state.DishData.Description }</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>



                                </div>

                                {/* AddOns product */ }
                                <div className="pb-3 container-fluid p-2">
                                    <div className="col-lg-12 col-12">
                                        <div className="additional-product">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5 className="no-margin">
                                                        <a className="card-link collapsed text-light-black fw-700" data-toggle="collapse" href="#additionalOne">
                                                            <span>Select Variant
                                                    {/* <span className="text-light-white fw-500 fs-12 padding-tb-10">Select one (Required)</span> */ }
                                                            </span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="additionalOne" className="collapse">
                                                    <div className="card-body padding-10">
                                                        {/* <button className="add-pro">Small Premium Roast Coffee (0 Cal.) <span>+$0.59</span>
                                                    <span className="close">+</span>
                                                </button> */}
                                                        { this.QuantityList(this.state.DishData.tblDishVariants) }
                                                        {/* { this.AddOnList(this.state.DishData.tblDishAddOns) } */ }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                {/* AddOns product End*/ }

                                {/* Price Varient product */ }

                                {/* Price Varient product End*/ }

                                {/* AddOns product */ }
                                <div className="container-fluid p-2">
                                    <div className="col-lg-12 col-12">
                                        <div className="additional-product">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5 className="no-margin">
                                                        <a className="card-link collapsed text-light-black fw-700" data-toggle="collapse" href="#additionalTwo">
                                                            <span>Select Add On
                                                    {/* <span className="text-light-white fw-500 fs-12 padding-tb-10">Select one (Required)</span> */ }
                                                            </span>
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="additionalTwo" className="collapse">
                                                    <div className="card-body padding-10">
                                                        {/* <button className="add-pro">Small Premium Roast Coffee (0 Cal.) <span>+$0.59</span>
                                                    <span className="close">+</span>
                                                </button> */}

                                                        { this.AddOnList(this.state.DishData.tblDishAddOns) }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                {/* AddOns product End*/ }
                            </div>
                            <div className="u-line" style={ { paddingLeft: "10px" } }>
                                <div className="container-fluid">
                                    <label style={ { color: "red", display: "none" } } id="quantityValidationError">Please select item quantity</label>
                                    <label style={ { color: "red", display: "none" } } id="varientValidationError">Please select item varient</label>
                                </div>
                            </div>
                            <div className="modal-footer padding-10">
                                <div className="container-fluid">
                                    <button className="btn-second btn-submit" onClick={ () => { this.AddToCart() } }>Add Bag : ${ parseFloat(this.state.DishOrder.FinalTotalWithAddOns).toFixed(2) }</button>
                                    <button className="btn-second btn-submit float-right" onClick={ () => { this.ResetOrderData() } }>Cancel</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* Place all Scripts Here */ }
            </>
        )
    }

    GetRatingReview() {
        ApiInstance().get('RatingReviewMaster/SelectAllRatingReviewByCookId?CookId=' + this.id)
            .then(response => {
                var data = response.data.data;

                if (data) {
                    let objdata = JSON.parse(data).Data
                    console.log(JSON.parse(objdata[0].JsonDatatblRatingReviewMaster))
                    // let DishList = JSON.parse(objdata[0].DishList)
                    // let TodaysSpecial = JSON.parse(objdata[0].TodaysSpecial)
                    // let WeekSpecial = JSON.parse(objdata[0].WeekSpecial)
                    // let PhotoGallery = JSON.parse(objdata[0].tblPhotoGalleryMaster)
                    // //console.log(objdata)
                    // this.setState({
                    //     RestarentData: objdata[0], DishList: DishList, TodaysSpecDish: TodaysSpecial,
                    //     WeekSpecDish: WeekSpecial, PhotoGallery: PhotoGallery
                    // });
                }
            }, error => {
                console.log(error);
            });
    }

    generateStar(AvgRating) {
        const list = [];

        for (var i = 0; i < AvgRating; i++) {
            list.push(<i className="fas fa-star text-yellow"></i>)
        }

        for (var i = 0; i < (5 - AvgRating); i++) {
            list.push(<i className="fas fa-star text-dark-white"></i>)
        }
        return list;
    }

    render() {

        //console.log(this.state.RestarentData)

        return (
            <>
                <Header FilterDishList={ this.FilterDishList }></Header>
                {/* Restaurent body */ }
                <div>
                    {/* restaurent top */ }
                    <div className="page-banner p-relative smoothscroll" id="menu">
                        <img src={ ((this.state.RestarentData.ProfilePhoto) ? (this.state.RestarentData.ProfilePhoto.length > 3) : false) ? (Config.BaseURL + this.state.RestarentData.ProfilePhoto) : "https://via.placeholder.com/1920x270" } className="img-fluid full-width" alt="banner" />
                        <div className="overlay-2">
                            <div className="container">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="back-btn">
                                            <button type="button" className="text-light-green"> <i className="fas fa-chevron-left" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="tag-share"> <span className="text-light-green share-tag">
                                            <i className="fas fa-chevron-right" />
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* restaurent top */ }
                    {/* restaurent details */ }
                    <section className="restaurent-details  u-line">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="heading padding-tb-10">
                                        <h3 className="text-light-black title fw-700 no-margin">{ this.state.RestarentData.BussinessName }</h3>
                                        <p className="text-light-black sub-title no-margin">{ this.state.RestarentData.CuisineType && this.state.RestarentData.CuisineType.replaceAll(",", ", ") }</p>
                                        <p className="text-light-black sub-title no-margin">Address: { this.state.RestarentData.Address1 } </p>
                                        <p className="text-light-black sub-title no-margin">Phone: { this.state.RestarentData.BussinessMobileNo } </p>
                                        <p className="text-light-black sub-title no-margin">Email: { this.state.RestarentData.BussinessEmail } </p>
                                        <div className="head-rating">

                                            <div className="rating">
                                                { this.generateStar(this.state.RestarentData.AvgRating) }

                                                <span className="text-light-black fs-12 rate-data">{ this.state.RestarentData.TotalReview } rating</span>
                                            </div>
                                            <div className="product-review">
                                                <div className="restaurent-details-mob">
                                                    <a href="#"> <span className="text-light-black"><i className="fas fa-info-circle" /></span>
                                                        <span className="text-dark-white">info</span>
                                                    </a>
                                                </div>
                                                <div className="restaurent-details-mob">
                                                    <a href="#"> <span className="text-light-black"><i className="fas fa-info-circle" /></span>
                                                        <span className="text-dark-white">info</span>
                                                    </a>
                                                </div>
                                                <div className="restaurent-details-mob">
                                                    <a href="#"> <span className="text-light-black"><i className="fas fa-info-circle" /></span>
                                                        <span className="text-dark-white">info</span>
                                                    </a>
                                                </div>
                                                <div className="restaurent-details-mob">
                                                    <a href="#"> <span className="text-light-black"><i className="fas fa-info-circle" /></span>
                                                        <span className="text-dark-white">info</span>
                                                    </a>
                                                </div>
                                                {/* <h6 className="text-light-black no-margin">91<span className="fs-14">% Food was good</span></h6>
                                                <h6 className="text-light-black no-margin">91<span className="fs-14">% Food was good</span></h6>
                                                <h6 className="text-light-black no-margin">91<span className="fs-14">% Food was good</span></h6> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="restaurent-logo">
                                        <img src="https://via.placeholder.com/80" className="img-fluid" alt="#" />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* restaurent details */ }
                    {/* restaurent tab */ }
                    <div className="restaurent-tabs u-line">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="restaurent-menu scrollnav">
                                        <ul className="nav nav-pills">
                                            <li className="nav-item"> <a className="nav-link active text-light-white fw-700" data-toggle="pill" href="#menu">Menu</a>
                                            </li>
                                            {/* <li className="nav-item"> <a className="nav-link text-light-white fw-700" data-toggle="pill" href="#about">About</a>
                                            </li> */}
                                            {/* <li className="nav-item"> <a className="nav-link text-light-white fw-700" data-toggle="pill" href="#review">Reviews</a>
                                            </li> */}
                                            <li className="nav-item"> <a className="nav-link text-light-white fw-700" href="#mapgallery">Map &amp; Gallery</a>
                                            </li>
                                        </ul>
                                        <div className="add-wishlist">
                                            {/* <img src="/assets/img/svg/013-heart-1.svg" alt="tag" /> */ }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* restaurent tab */ }
                    {/* restaurent address */ }
                    {/* <div className="restaurent-address u-line">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="address-details">
                                        <div className="address">
                                            <div className="delivery-address"> <a href="order-details.html" className="text-light-black">Delivery, ASAP (45–55m)</a>
                                                <div className="delivery-type"> <span className="text-success fs-12 fw-500">No minimun</span><span className="text-light-white">, Free Delivery</span>
                                                </div>
                                            </div>
                                            <div className="change-address"> <a href="checkout.html" className="fw-500">Change</a>
                                            </div>
                                        </div>
                                        <p className="text-light-white no-margin">Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* restaurent address */ }
                    {/* restaurent meals */ }
                    <section className="section-padding restaurent-meals bg-light-theme">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-2 col-lg-2">
                                    {/* <TodaysSpecial></TodaysSpecial> */ }
                                </div>
                                <div className="col-xl-7 col-lg-7">

                                    { this.state.TodaysSpecDish.length > 0 && < div className="row">
                                        <div className="col-lg-12 restaurent-meal-head mb-md-40">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="section-header-left">
                                                        <h3 className="text-light-black header-title">
                                                            <a className="card-link text-light-black no-margin" data-toggle="collapse" href="#collapseTwo">
                                                                Today's Special
                                                             </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div id="collapseTwo" className="collapse show">
                                                    <div className="card-body no-padding">
                                                        <div className="row">
                                                            {
                                                                this.LoadTodaysSpecialDishList()
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> }
                                    { this.state.WeekSpecDish.length > 0 && <div className="row">
                                        <div className="col-lg-12 restaurent-meal-head mb-md-40">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="section-header-left">
                                                        <h3 className="text-light-black header-title">
                                                            <a className="card-link text-light-black no-margin" data-toggle="collapse" href="#collapseThree">
                                                                Week Special
                                                             </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div id="collapseThree" className="collapse show">
                                                    <div className="card-body no-padding">
                                                        <div className="row">
                                                            {
                                                                this.LoadWeekSpecialDishList()
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <div className="row">
                                        <div className="col-lg-12 restaurent-meal-head mb-md-40">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="section-header-left">
                                                        <h3 className="text-light-black header-title">
                                                            <a className="card-link text-light-black no-margin" data-toggle="collapse" href="#collapseOne">
                                                                Our Dishies
                                                             </a>
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div id="collapseOne" className="collapse show">
                                                    <div className="card-body no-padding">
                                                        <div className="row">
                                                            {
                                                                this.LoadDishiesList()
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-3">
                                    <Cart CartOrder={ this.state.CartOrder }></Cart>
                                </div>

                            </div>
                        </div>
                    </section>
                    {/* restaurent meals */ }
                    {/* restaurent about */ }

                    {/* restaurent about */ }
                    {/* map gallery */ }
                    <div className="map-gallery-sec section-padding bg-light-theme smoothscroll" id="mapgallery">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="main-box">
                                        <div className="row">
                                            <div className="col-md-6 map-pr-0">
                                                {/* <iframe id="locmap" src="https://maps.google.com/maps?q=university%20of%20san%20francisco&t=&z=13&ie=UTF8&iwloc=&output=embed" /> */ }
                                                <iframe width="100%" height="100%" id="gmap_canvas"
                                                    src={ "https://maps.google.com/maps?q=" + this.state.RestarentData.Latitude + "," + this.state.RestarentData.Longitude + "&z=15&ie=UTF8&iwloc=&output=embed" } frameBorder="0"
                                                    scrolling="no" marginHeight="0" marginWidth="0">

                                                </iframe>
                                            </div>
                                            <div className="col-md-6 map-pl-0">
                                                <div className="gallery-box padding-10">
                                                    <ul className="gallery-img">
                                                        {
                                                            this.state.PhotoGallery.map((item, index) =>
                                                                <li>
                                                                    <a className="li-ctrl" href="#" title={ item.Description } >
                                                                        <img src={ (item.PhotoPath) ? (Config.BaseURL + item.PhotoPath) : "https://via.placeholder.com/173" } className="img-fluid full-width parentimage" alt={ item.Description } />
                                                                    </a>
                                                                </li>
                                                            )
                                                        }
                                                        {/* <li>
                                                            <a className="image-popup" href="https://via.placeholder.com/173" title="Image title">
                                                                <img src="https://via.placeholder.com/173" className="img-fluid full-width" alt="9.jpg" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="image-popup" href="https://via.placeholder.com/173" title="Image title">
                                                                <img src="https://via.placeholder.com/173" className="img-fluid full-width" alt="9.jpg" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="image-popup" href="https://via.placeholder.com/173" title="Image title">
                                                                <img src="https://via.placeholder.com/173" className="img-fluid full-width" alt="9.jpg" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="image-popup" href="https://via.placeholder.com/173" title="Image title">
                                                                <img src="https://via.placeholder.com/173" className="img-fluid full-width" alt="9.jpg" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="image-popup" href="https://via.placeholder.com/173" title="Image title">
                                                                <img src="https://via.placeholder.com/173" className="img-fluid full-width" alt="9.jpg" />
                                                            </a>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* map gallery */ }
                    {/* restaurent reviews */ }
                    {/* <section className="section-padding restaurent-review smoothscroll" id="review">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="section-header-left">
                                        <h3 className="text-light-black header-title title">Reviews for Great Burger</h3>
                                    </div>
                                    <div className="restaurent-rating mb-xl-20">
                                        <div className="star"> <span className="text-yellow fs-16">
                                            <i className="fas fa-star" />
                                        </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-dark-white fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-dark-white fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                        </div> <span className="fs-12 text-light-black">58 Ratings</span>
                                    </div>
                                    <p className="text-light-black mb-xl-20">Here's what people are saying:</p>
                                    <ul>
                                        <li>
                                            <h6 className="text-light-black mb-1">73%</h6>
                                            <span className="text-light-black fs-12 fw-400">Food was good</span>
                                        </li>
                                        <li>
                                            <h6 className="text-light-black mb-1">85%</h6>
                                            <span className="text-light-black fs-12 fw-400">Delivery was on time</span>
                                        </li>
                                        <li>
                                            <h6 className="text-light-black mb-1">68%</h6>
                                            <span className="text-light-black fs-12 fw-400">Order was accurate</span>
                                        </li>
                                    </ul>
                                    <div className="u-line" />
                                </div>
                                <div className="col-md-12">
                                    <div className="review-box">
                                        <div className="review-user">
                                            <div className="review-user-img">
                                                <img src="https://via.placeholder.com/40" className="rounded-circle" alt="#" />
                                                <div className="reviewer-name">
                                                    <p className="text-light-black fw-600">Sarra <small className="text-light-white fw-500">New York, (NY)</small>
                                                    </p> <i className="fas fa-trophy text-black" /><span className="text-light-black">Top Reviewer</span>
                                                </div>
                                            </div>
                                            <div className="review-date"> <span className="text-light-white">Sep 20, 2019</span>
                                            </div>
                                        </div>
                                        <div className="ratings"> <span className="text-yellow fs-16">
                                            <i className="fas fa-star" />
                                        </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="ml-2 text-light-white">2 days ago</span>
                                        </div>
                                        <p className="text-light-black">Delivery was fast and friendly. Food was not great especially the salad. Will not be ordering from again. Too many options to settle for this place.</p> <span className="text-light-white fs-12 food-order">Kathy
                                        ordered:</span>
                                        <ul className="food">
                                            <li>
                                                <button className="add-pro bg-gradient-red">Coffee <span className="close">+</span>
                                                </button>
                                            </li>
                                            <li>
                                                <button className="add-pro bg-dark">Pizza <span className="close">+</span>
                                                </button>
                                            </li>
                                            <li>
                                                <button className="add-pro bg-gradient-green">Noodles <span className="close">+</span>
                                                </button>
                                            </li>
                                            <li>
                                                <button className="add-pro bg-gradient-orange">Burger <span className="close">+</span>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="review-box">
                                        <div className="review-user">
                                            <div className="review-user-img">
                                                <img src="https://via.placeholder.com/40" className="rounded-circle" alt="#" />
                                                <div className="reviewer-name">
                                                    <p className="text-light-black fw-600">Sarra <small className="text-light-white fw-500">New York, (NY)</small>
                                                    </p> <i className="fas fa-trophy text-black" /><span className="text-light-black">Top Reviewer</span>
                                                </div>
                                            </div>
                                            <div className="review-date"> <span className="text-light-white">Sep 20, 2019</span>
                                            </div>
                                        </div>
                                        <div className="ratings"> <span className="text-yellow fs-16">
                                            <i className="fas fa-star" />
                                        </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="ml-2 text-light-white">2 days ago</span>
                                        </div>
                                        <p className="text-light-black">Delivery was fast and friendly. Food was not great especially the salad. Will not be ordering from again. Too many options to settle for this place.</p> <span className="text-light-white fs-12 food-order">Kathy
                    ordered:</span>
                                        <ul className="food">
                                            <li>
                                                <button className="add-pro bg-gradient-red">Coffee <span className="close">+</span>
                                                </button>
                                            </li>
                                            <li>
                                                <button className="add-pro bg-dark">Pizza <span className="close">+</span>
                                                </button>
                                            </li>
                                            <li>
                                                <button className="add-pro bg-gradient-green">Noodles <span className="close">+</span>
                                                </button>
                                            </li>
                                            <li>
                                                <button className="add-pro bg-gradient-orange">Burger <span className="close">+</span>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="review-box">
                                        <div className="review-user">
                                            <div className="review-user-img">
                                                <img src="https://via.placeholder.com/40" className="rounded-circle" alt="#" />
                                                <div className="reviewer-name">
                                                    <p className="text-light-black fw-600">Sarra <small className="text-light-white fw-500">New York, (NY)</small>
                                                    </p> <i className="fas fa-trophy text-black" /><span className="text-light-black">Top Reviewer</span>
                                                </div>
                                            </div>
                                            <div className="review-date"> <span className="text-light-white">Sep 20, 2019</span>
                                            </div>
                                        </div>
                                        <div className="ratings"> <span className="text-yellow fs-16">
                                            <i className="fas fa-star" />
                                        </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="text-yellow fs-16">
                                                <i className="fas fa-star" />
                                            </span>
                                            <span className="ml-2 text-light-white">2 days ago </span>
                                        </div>
                                        <p className="text-light-black">Delivery was fast and friendly. Food was not great especially the salad. Will not be ordering from again. Too many options to settle for this place.</p> <span className="text-light-white fs-12 food-order">Kathy
                    ordered:</span>
                                        <ul className="food">
                                            <li>
                                                <button className="add-pro bg-gradient-red">Coffee <span className="close">+</span>
                                                </button>
                                            </li>
                                            <li>
                                                <button className="add-pro bg-dark">Pizza <span className="close">+</span>
                                                </button>
                                            </li>
                                            <li>
                                                <button className="add-pro bg-gradient-green">Noodles <span className="close">+</span>
                                                </button>
                                            </li>
                                            <li>
                                                <button className="add-pro bg-gradient-orange">Burger <span className="close">+</span>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="review-img">
                                        <img src="/assets/img/review-footer.png" className="img-fluid" alt="#" />
                                        <div className="review-text">
                                            <h2 className="text-light-white mb-2 fw-600">Be one of the first to review</h2>
                                            <p className="text-light-white">Order now and write a review to give others the inside scoop.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                    {/* restaurent reviews */ }
                    {/* offer near */ }

                    {/* offer near */ }
                </div>
                {/* Restaurnet body end */ }
                <Footer></Footer>

                { this.ModelPopupItemList() }
            </>
        )
    }
}
