// First we need to import axios.js
import axios from 'axios';
import React, { Component } from 'react';
import Config from '../Config'



const instance = () => {

    const tokenData = JSON.parse(localStorage.getItem("tokenData"));
    //console.log(tokenData);

    const access_token = (tokenData !== null) ? tokenData.access_token : "";
    const UserId = (tokenData !== null) ? tokenData.UserId : "";

    // Next we make an 'instance' of it
    const axiosinstance = axios.create({
        // .. where we make our configurations
        baseURL: Config.apiBaseURL

    });


    // Where you would set stuff like your 'Authorization' header, etc ...
    axiosinstance.defaults.headers.common['Authorization'] = 'bearer ' + access_token;
    axiosinstance.defaults.headers.common['UserId'] = UserId;

    // Also add/ configure interceptors && all the other cool stuff

    axiosinstance.interceptors.request.use(request => {


        // console.log(request);
        // Edit request config
        return request;
    }, error => {
        console.log(error);
        return Promise.reject(error);
    });

    axiosinstance.interceptors.response.use(response => {
        // console.log(response);

        // Edit response config
        return response;
    }, error => {
        console.log(error);
        return Promise.reject(error);
    });

    return axiosinstance;
}




export default instance;