import React, { Component } from 'react'
import Footer from '../Footer'
import Header from '../Header'
import ApiInstance from '../../Utilities/axiosConfig';
import Config from '../../Config';
import $ from 'jquery';
import { ThemeProvider } from 'react-bootstrap';
import LoaderComponent from '../../Components/LoaderComponent';
import Notification from '../../Components/Notification';

export default class OrderDetails extends Component {

    constructor (props) {
        super(props)
        this.id = this.props.match.params.id;

        this.state = {
            OrderData: {},
            tblOrderChild: [],
            tblDisputeMaster: {},
            tblRatingReviewMaster: {},
            OrderStatus: '',
            file: '',
            StarCount: 0
        }
        this._handleSubmitEvent = this.handleSubmit.bind(this);
        this._handleSubmitEventRating = this.handleSubmitRating.bind(this);
        this.NotificationRef = React.createRef()
    }

    componentDidMount() {
        function formValidation() {
            window.addEventListener('load', function () {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function (form) {
                    form.addEventListener('submit', function (event) {


                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form.classList.add('was-validated');
                        }
                        else {
                            form.classList.remove('was-validated');
                        }
                    }, false);
                });

            }, false);
        }
        formValidation();

        this.GetOrderDetails();

        setInterval(this.GetOrderDetails, (1000 * 60));
    }


    GetOrderDetails = () => {
        ApiInstance().post('Report/ReportCustomerkOrderMasterDetailsByOrderMasterId', this.id, { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                var data = response.data.data;

                if (data) {

                    var objdata = JSON.parse(data)
                    console.log(objdata)
                    let tblOrderChild = JSON.parse(objdata.Data[0].tblOrderChild);

                    let tblDisputeMaster = JSON.parse(objdata.Data[0].tblDisputeMaster)[0]
                    let tblRatingReviewMaster = JSON.parse(objdata.Data[0].tblRatingReviewMaster)[0]
                    let StarCount = 0;
                    if ((tblRatingReviewMaster)) {
                        document.getElementById("txtReview").value = tblRatingReviewMaster.Review
                        document.getElementById("txtReview").readOnly = true;
                        document.getElementById("ratingSubmit").style.display = "none";
                        StarCount = tblRatingReviewMaster.Rating
                    }
                    this.setState({
                        OrderData: objdata.Data[0], StarCount: StarCount,
                        tblOrderChild: tblOrderChild, tblDisputeMaster: tblDisputeMaster
                    });
                }
            }, error => {
                console.log(error);
            });
    }

    AddOnsList(items) {

        var objAddons = JSON.parse(items);
        var addonstring = [];
        objAddons.map((item) => addonstring.push(item.AddOnsName))
        return addonstring.join();
    }
    printDiv() {

        var printContents = document.getElementById('receipt').innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;


    }

    setFile(e) {
        console.log(e.target)
        if (this.validate(e.target)) {
            this.setState({ file: e.target.files[0] });
            console.log(e.target.files[0]);
        }
    }
    validate(file) {

        var valid = true;
        var file_name = "";
        var file_type = "";
        var file_size = "";
        var error_msg = "";
        var valid_size = 5 * 1000 * 1000;
        var display_error = document.getElementById('file_error');

        if (file.files.length != 0) {
            file_name = file.files[0].name;
            file_size = file.files[0].size;
            file_type = file.files[0].type;

            if (file_type != "image/png" && file_type != "image/jpeg" && file_type != "image/bmp") {
                valid = false;
                error_msg = error_msg + "\n* Only 'PNG', 'JPG/JPEG' and 'BMP'  file type supported.";
            }
            if (file_size > valid_size) {
                valid = false;
                error_msg = error_msg + "\n* Filesize should be upto 5MB.";
            }
        }
        else {
            valid = false;
            error_msg = error_msg + "\n* Please select any image file.";
        }
        if (valid == true) {
            console.log(valid)
            display_error.innerText = '';
            /*Write ajax code here to send file to the server.*/


            var previews = $("#previews");
            previews.empty();
            Array.prototype.slice.call(file.files)
                .forEach(function (file, idx) {
                    var reader = new FileReader();
                    reader.onload = function (event) {
                        $("<img />", {
                            "src": event.target.result,
                            "class": 'd-block w-100'
                        }).appendTo(previews);
                    };
                    reader.readAsDataURL(file);
                });

            return true;
        }
        else {
            console.log(error_msg)
            display_error.innerText = error_msg;
            document.getElementById("previewimage").src = "https://via.placeholder.com/150x151"
            return false;
        }
    }

    handleSubmit = event => {
        event.preventDefault()
        let DisputeData = {
            OrderMasterId: this.id,
            ClientDisputeReason: document.getElementById("ClientDisputeReason").value,
            OrderAmount: this.state.OrderData.Grandtotal
        }

        if (event.target.checkValidity()) {

            this.setState({ ActiveLoader: true })
            const formData = new FormData();
            formData.append("file", this.state.file);
            formData.append("data", JSON.stringify(DisputeData));


            ApiInstance().post("DisputeMaster/InserttblDisputeMaster", formData, { headers: { 'Content-Type': 'multipart/form-data', } })
                .then(response => {
                    let data = response.data;
                    console.log(data);
                    if (data.status) {
                        this.NotificationRef.current.createNotification("success", "We have registered your query and our support team will get back to you soon!", "Success!")
                        document.getElementById("ClientDisputeReason").value = "";
                        this.GetOrderDetails();
                    }
                    else {
                        let errobj = document.getElementById("errmessage")
                        errobj.innerText = data.data
                    }
                    this.setState({ ActiveLoader: false })

                }, error => {

                    this.setState({ ActiveLoader: false })
                    console.log(error);
                    this.NotificationRef.current.createNotification("error", "Error in Profile update", "Error!")
                });

        }
        else {
            console.log(event.target.checkValidity())
        }

    }

    handleSubmitRating = event => {
        event.preventDefault()
        var txtreview = document.getElementById("txtReview")
        let objRating =
        {
            CookId: this.state.OrderData.CookId,
            CustomerId: this.state.OrderData.CustomerId,
            OrderMasterId: this.state.OrderData.OrderMasterId,
            Rating: this.state.StarCount,
            Review: txtreview.value

        }

        if (event.target.checkValidity()) {

            this.setState({ ActiveLoader: true })

            ApiInstance().post("RatingReviewMaster/InserttblRatingReviewMaster", objRating, { headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    let data = response.data;
                    console.log(data);
                    if (data.status) {
                        this.NotificationRef.current.createNotification("success", "Thank you for your valuable feedback!", "Success!")
                        document.getElementById("txtReview").value = "";
                        this.GetOrderDetails();
                    }
                    else {
                        let errobj = document.getElementById("errmessage")
                        errobj.innerText = data.data
                    }
                    this.setState({ ActiveLoader: false })

                }, error => {

                    this.setState({ ActiveLoader: false })
                    console.log(error);
                    this.NotificationRef.current.createNotification("error", "Error in Review insert", "Error!")
                });

        }
        else {
            console.log(event.target.checkValidity())
        }

    }

    SetStar(val) {
        if (val == this.state.StarCount && this.state.StarCount == 1)
            this.setState({ StarCount: 0 })
        else
            this.setState({ StarCount: val })
    }
    render() {
        console.log(this.state.OrderData)
        console.log(this.state.tblDisputeMaster)

        return (
            <>
                <LoaderComponent ActiveLoader={ this.state.ActiveLoader }></LoaderComponent>
                <Notification ref={ this.NotificationRef }></Notification>
                <Header></Header>
                <>
                    {/* tracking map */ }
                    <section className="checkout-page section-padding bg-light-theme">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="tracking-sec">
                                        <div className="tracking-details padding-20 p-relative">
                                            <h5 className="text-light-black fw-600">{ this.state.OrderData.BussinessName }</h5>
                                            {/* <span className="text-light-white">Estimated Delivery time</span>
                                            <h2 className="text-light-black fw-700 no-margin">9:00pm-9:10pm</h2> */}
                                            <div id="add-listing-tab" className="step-app">
                                                <ul className="step-steps">
                                                    <li className={ (this.state.OrderData.OrderStatusId === 1) ? 'active' : ((this.state.OrderData.OrderStatusId > 1) ? 'done' : '') }>
                                                        <a href="#"> <span className="number" />
                                                            <span className="step-name">Order sent<br />8:38pm</span>
                                                        </a>
                                                    </li>
                                                    <li className={ (this.state.OrderData.OrderStatusId === 2) ? 'active' : ((this.state.OrderData.OrderStatusId > 2) ? 'done' : '') }>
                                                        <a href="#"> <span className="number" />
                                                            <span className="step-name">Order Accepted</span>
                                                        </a>
                                                    </li>
                                                    <li className={ (this.state.OrderData.OrderStatusId === 3) ? 'active' : ((this.state.OrderData.OrderStatusId > 3) ? 'done' : '') }>
                                                        <a href="#"> <span className="number" />
                                                            <span className="step-name">Ready For Pickup</span>
                                                        </a>
                                                    </li>
                                                    <li className={ (this.state.OrderData.OrderStatusId === 4) ? 'active' : ((this.state.OrderData.OrderStatusId > 4) ? 'done' : '') } >
                                                        <a href="#"> <span className="number" />
                                                            <span className="step-name">Delivered</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                {/* <button type="button" className="fullpageview text-light-black fw-600" data-modal="modal-12">Full Page View</button> */ }
                                            </div>
                                        </div>
                                        <div className="tracking-map">
                                            <div id="pickupmap" />
                                        </div>
                                    </div>
                                    {/* recipt */ }
                                    <h5 className="text-light-black fw-600 title">&nbsp;<span>
                                        <a href="#" className="fs-12" onClick={ () => { this.printDiv() } }>Print recipt</a>

                                    </span></h5>
                                    <div className="recipt-sec padding-20" id="receipt">
                                        <div className="recipt-name title u-line full-width mb-xl-20">
                                            <div className="recipt-name-box">
                                                <h5 className="text-light-black fw-600 mb-2">{ this.state.OrderData.BussinessName }</h5>
                                                {/* <p className="text-light-white ">Estimated Delivery time</p> */ }
                                            </div>
                                            {/* <div className="countdown-box">
                                                <div className="time-box"> <span id="mb-hours" />
                                                </div>
                                                <div className="time-box"> <span id="mb-minutes" />
                                                </div>
                                                <div className="time-box"> <span id="mb-seconds" />
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="u-line mb-xl-20">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="recipt-name full-width padding-tb-10 pt-0">
                                                        <h5 className="text-light-black fw-600">To:</h5>
                                                        <span className="text-light-white ">{ this.state.OrderData.Customer_FirstName + " " + this.state.OrderData.Customer_LastName }</span>
                                                        {/* <span className="text-light-white ">Home</span>
                                                        <span className="text-light-white ">314 79th St</span>
                                                        <span className="text-light-white ">Rite Aid, Brooklyn, NY, 11209</span> */}
                                                        <p className="text-light-white ">{ this.state.OrderData.Customer_EmailId }</p>
                                                        <p className="text-light-white ">{ this.state.OrderData.Customer_MobileNo }</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="recipt-name full-width padding-tb-10 pt-0">
                                                        <h5 className="text-light-black fw-600">Pickup from:</h5>
                                                        <span className="text-light-white ">{ this.state.OrderData.Cook_FirstName + " " + this.state.OrderData.Cook_LastName }</span>
                                                        <span className="text-light-white ">{ this.state.OrderData.BussinessName }</span>
                                                        <span className="text-light-white ">{ this.state.OrderData.Address1 }</span>
                                                        <p className="text-light-white ">{ this.state.OrderData.Customer_EmailId }</p>
                                                        <p className="text-light-white ">{ this.state.OrderData.Customer_MobileNo }</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="recipt-name full-width padding-tb-10 pt-0">
                                                        <h5 className="text-light-black fw-600">Delivery instructions</h5>
                                                        <p className="text-light-white ">
                                                            { this.state.OrderData.ExtraNotes }
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="u-line mb-xl-20">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h5 className="text-light-black fw-600 title">Your Order <span><a href="#" className="fs-12"></a></span></h5>
                                                    <p className="title text-light-white">{ this.state.OrderData.OrderInsertDateTime } <span className="text-light-black">Order #{ this.state.OrderData.OrderMasterId }</span>
                                                    </p>
                                                </div>
                                                <div className="col-lg-12">
                                                    { this.state.tblOrderChild.map((item, index) =>
                                                        <div className="checkout-product" key={ item.OrderChildId }>
                                                            <div className="img-name-value">
                                                                <div className="product-img">
                                                                    <a href="#">
                                                                        <img src={ (item.Photo) ? (Config.BaseURL + item.Photo) : "https://via.placeholder.com/100" } alt="#" style={ { maxWidth: "100px", maxHeight: "100px" } } />
                                                                    </a>
                                                                </div>
                                                                <div className="product-value">
                                                                    <span className="text-light-white">{ item.BuyQuantity }</span>
                                                                </div>
                                                                <div className="product-name">
                                                                    <span><a href="#" className="text-light-white">
                                                                        { item.DishName } { item.VariantName && ("(" + item.VariantName + ")") }
                                                                        <br /> Addons: <span>{ this.AddOnsList(item.AddOnsArray) }</span>
                                                                    </a></span>
                                                                </div>
                                                            </div>
                                                            <div className="price"> <span className="text-light-white">${ item.FinalPrice }</span>
                                                            </div>
                                                        </div>
                                                    ) }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="payment-method mb-md-40">
                                                    <h5 className="text-light-black fw-600">Payment Method</h5>
                                                    <div className="method-type"> <i className="far fa-credit-card text-dark-white" />
                                                        <span className="text-light-white">
                                                            Credit Card
                                                            <br></br>{ this.state.OrderData.TransactionNumber }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="price-table u-line">
                                                    <div className="item"> <span className="text-light-white">Item subtotal:</span>
                                                        <span className="text-light-white">${ this.state.OrderData.TotalAmount }</span>
                                                    </div>
                                                    <div className="item"> <span className="text-light-white">Processing charge:</span>
                                                        <span className="text-light-white">${ this.state.OrderData.ProcessingCharge }</span>
                                                    </div>

                                                    <div className="item"> <span className="text-light-white">Cook tip:</span>
                                                        <span className="text-light-white">${ this.state.OrderData.Tip }</span>
                                                    </div>
                                                </div>
                                                <div className="total-price padding-tb-10">
                                                    <h5 className="title text-light-black fw-700">Total: <span>${ this.state.OrderData.Grandtotal }</span></h5>
                                                </div>
                                            </div>
                                            {/* <div className="col-12 d-flex"> <a href="#" className="btn-first white-btn fw-600 help-btn">Need Help?</a>
                                            </div> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <br></br><br></br>
                            <div className="row tracking-sec">

                                <div className="col-md-12">
                                    <br></br><br></br>
                                    <h5 className="text-light-black fw-600">Rating and Review</h5>
                                    <form className="needs-validation" noValidate onSubmit={ this._handleSubmitEventRating } id="registrationform">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-6">
                                                <div className="form-group">
                                                    <label className="text-light-white fs-14">Tap to rate your experience </label>
                                                    <div className="star">
                                                        <span onClick={ () => { this.SetStar(1) } } className={ (this.state.StarCount >= 1) ? "text-yellow fs-16" : "text-dark-white fs-16" } > <i className="fas fa-star" /> </span>
                                                        <span onClick={ () => { this.SetStar(2) } } className={ (this.state.StarCount >= 2) ? "text-yellow fs-16" : "text-dark-white fs-16" }><i className="fas fa-star" /></span>
                                                        <span onClick={ () => { this.SetStar(3) } } className={ (this.state.StarCount >= 3) ? "text-yellow fs-16" : "text-dark-white fs-16" }><i className="fas fa-star" /></span>
                                                        <span onClick={ () => { this.SetStar(4) } } className={ (this.state.StarCount >= 4) ? "text-yellow fs-16" : "text-dark-white fs-16" }><i className="fas fa-star" /></span>
                                                        <span onClick={ () => { this.SetStar(5) } } className={ (this.state.StarCount == 5) ? "text-yellow fs-16" : "text-dark-white fs-16" }><i className="fas fa-star" /></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-6">
                                                <div className="form-group">
                                                    <label className="text-light-white fs-14">Share your experiene about Restaurant</label>
                                                    <textarea name="txtReview" id="txtReview" className="form-control form-control-submit" placeholder="" required  ></textarea>
                                                    <div className="invalid-feedback">
                                                        Please enter your message.
                                                             </div>
                                                    <div className="invalid-feedback d-block" id="errmessage"> </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-6">
                                                <div className="col-4">&nbsp;</div>
                                                <div className="col-4">

                                                    <div className="form-group">


                                                        <button type="submit" className="btn-first green-btn text-custom-white fw-500 lg-btn" id="ratingSubmit">Submit</button>


                                                    </div>
                                                </div>
                                                <div className="col-4">&nbsp;</div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <br></br><br></br>
                            <div className="row tracking-sec">

                                <div className="col-md-12">
                                    <br></br><br></br>
                                    <h5 className="text-light-black fw-600">Get support</h5>
                                    { !this.state.tblDisputeMaster && <form className="needs-validation" noValidate onSubmit={ this._handleSubmitEvent } id="registrationform">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-6">
                                                <div className="form-group">
                                                    <label className="text-light-white fs-14">Description</label>
                                                    <textarea name="ClientDisputeReason" id="ClientDisputeReason" className="form-control form-control-submit" placeholder="" required maxLength="200"></textarea>
                                                    <div className="invalid-feedback">
                                                        Please enter your qury.
                                                             </div>
                                                    <div className="invalid-feedback d-block" id="errmessage"> </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-6">
                                                <input type="file" name="File" className="text-light-black fw-700" onChange={ e => this.setFile(e) } />
                                                <div id="file_error" style={ { color: "red" } }></div>

                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-6">
                                                <div className="col-4">&nbsp;</div>
                                                <div className="col-4">

                                                    <div className="form-group">

                                                        {
                                                            <button type="submit" className="btn-first green-btn text-custom-white fw-500 lg-btn">Submit</button>
                                                        }

                                                    </div>
                                                </div>
                                                <div className="col-4">&nbsp;</div>
                                            </div>
                                        </div>
                                    </form>
                                    }
                                </div>
                                <div className="col-md-12">
                                    { this.state.tblDisputeMaster && <table className="table">
                                        <tr>
                                            <th>Support Ticket No.</th>
                                            <th>Status</th>
                                            <th>Message</th>
                                            <th>File</th>
                                            <th>Admin response</th>
                                            <th>Refund amount</th>
                                        </tr>
                                        {
                                            this.state.tblDisputeMaster &&
                                            <tr>
                                                <td>{ this.state.tblDisputeMaster.DisputeId }</td>
                                                <td>{ (this.state.tblDisputeMaster.IsDisputeOpen) ? "Open" : "Close" }</td>
                                                <td>{ this.state.tblDisputeMaster.ClientDisputeReason }</td>
                                                <td>{ (this.state.tblDisputeMaster.ClientDisputePhoto) ? <a href={ (Config.BaseURL + this.state.tblDisputeMaster.ClientDisputePhoto) } target="_blank">View</a> : "" }</td>
                                                <td>{ this.state.tblDisputeMaster.AdminReply }</td>
                                                <td>{ this.state.tblDisputeMaster.RefundAmount }</td>

                                            </tr>
                                        }
                                    </table> }
                                    <br></br>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* tracking map */ }
                </>
                <Footer></Footer>
            </>
        )
    }
}
