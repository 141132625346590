import React from "react";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default class LoaderComponent extends React.Component {
    constructor (props) {
        super(props);

        let ActiveLoader = this.props.ActiveLoader

        this.state = {
            loading: ActiveLoader
        };
    }


    // activeloader() {
    //     let obj = this.state.loading
    //     this.setState({ loading: !obj })
    // }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            loading: nextProps.ActiveLoader,
        };
    }
    render() {

        const divcss = {
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, .6)",
            zIndex: "1001",
            display: (this.state.loading ? "block" : "none")
        }

        return (
            <>
                <div className="sweet-loading" style={ divcss }>
                    <GridLoader
                        css={ override }
                        size={ 15 }
                        color={ "#ff5757" }
                        loading={ this.state.loading }
                    />
                </div>
                {/* <button onClick={ () => { this.activeloader() } }>Clikc</button> */ }
            </>
        );
    }
}