import React, { Component } from 'react'
import Footer from '../Footer'
import Header from '../Header'
import ApiInstance from '../../Utilities/axiosConfig';
import Config from '../../Config';


export default class OrderDetailsReport extends Component {
    constructor (props) {
        super(props)
        this.id = this.props.match.params.id;

        this.state = {
            OrderData: {},
            tblOrderChild: [],
            OrderStatus: ''
        }
    }

    componentDidMount() {
        this.GetOrderDetails();

        setInterval(this.GetOrderDetails, (1000 * 60));
    }


    GetOrderDetails = () => {
        ApiInstance().post('Report/ReportCustomerkOrderMasterDetailsByOrderMasterId', this.id, { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                var data = response.data.data;

                if (data) {

                    var objdata = JSON.parse(data)
                    console.log(objdata)
                    let tblOrderChild = JSON.parse(objdata.Data[0].tblOrderChild);
                    // console.log(tblOrderChild)

                    this.setState({
                        OrderData: objdata.Data[0],
                        tblOrderChild: tblOrderChild
                    });
                }
            }, error => {
                console.log(error);
            });
    }

    AddOnsList(items) {

        var objAddons = JSON.parse(items);
        var addonstring = [];
        objAddons.map((item) => addonstring.push(item.AddOnsName))
        return addonstring.join();
    }
    printDiv() {

        var printContents = document.getElementById('receipt').innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;


    }
    render() {
        console.log(this.state.OrderData)

        return (
            <>
                <Header></Header>
                <>
                    {/* tracking map */ }
                    <section className="checkout-page section-padding bg-light-theme">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    {/* recipt */ }
                                    <h5 className="text-light-black fw-600 title">&nbsp;<span>
                                        <a href="#" className="fs-12" onClick={ () => { this.printDiv() } }>Print recipt</a>

                                    </span></h5>
                                    <div className="recipt-sec padding-20" id="receipt">
                                        <div className="recipt-name title u-line full-width mb-xl-20">
                                            <div className="recipt-name-box">
                                                <h5 className="text-light-black fw-600 mb-2">{ this.state.OrderData.BussinessName }</h5>
                                                <p className="text-light-white ">Estimated Delivery time</p>
                                            </div>
                                            {/* <div className="countdown-box">
                                                <div className="time-box"> <span id="mb-hours" />
                                                </div>
                                                <div className="time-box"> <span id="mb-minutes" />
                                                </div>
                                                <div className="time-box"> <span id="mb-seconds" />
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="u-line mb-xl-20">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="recipt-name full-width padding-tb-10 pt-0">
                                                        <h5 className="text-light-black fw-600">To:</h5>
                                                        <span className="text-light-white ">{ this.state.OrderData.Customer_FirstName + " " + this.state.OrderData.Customer_LastName }</span>
                                                        {/* <span className="text-light-white ">Home</span>
                                                        <span className="text-light-white ">314 79th St</span>
                                                        <span className="text-light-white ">Rite Aid, Brooklyn, NY, 11209</span> */}
                                                        <p className="text-light-white ">{ this.state.OrderData.Customer_EmailId }</p>
                                                        <p className="text-light-white ">{ this.state.OrderData.Customer_MobileNo }</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="recipt-name full-width padding-tb-10 pt-0">
                                                        <h5 className="text-light-black fw-600">Pickup from:</h5>
                                                        <span className="text-light-white ">{ this.state.OrderData.Cook_FirstName + " " + this.state.OrderData.Cook_LastName }</span>
                                                        <span className="text-light-white ">{ this.state.OrderData.BussinessName }</span>
                                                        <span className="text-light-white ">{ this.state.OrderData.Address1 }</span>
                                                        <p className="text-light-white ">{ this.state.OrderData.Customer_EmailId }</p>
                                                        <p className="text-light-white ">{ this.state.OrderData.Customer_MobileNo }</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="recipt-name full-width padding-tb-10 pt-0">
                                                        <h5 className="text-light-black fw-600">Delivery instructions</h5>
                                                        <p className="text-light-white ">
                                                            { this.state.OrderData.ExtraNotes }
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="u-line mb-xl-20">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h5 className="text-light-black fw-600 title">Your Order <span><a href="#" className="fs-12"></a></span></h5>
                                                    <p className="title text-light-white">{ this.state.OrderData.OrderInsertDateTime } <span className="text-light-black">Order #{ this.state.OrderData.OrderMasterId }</span>
                                                    </p>
                                                </div>
                                                <div className="col-lg-12">
                                                    { this.state.tblOrderChild.map((item, index) =>
                                                        <div className="checkout-product" key={ item.OrderChildId }>
                                                            <div className="img-name-value">
                                                                <div className="product-img">
                                                                    <a href="#">
                                                                        <img src={ (item.Photo) ? (Config.BaseURL + item.Photo) : "https://via.placeholder.com/100" } alt="#" style={ { maxWidth: "100px", maxHeight: "100px" } } />
                                                                    </a>
                                                                </div>
                                                                <div className="product-value">
                                                                    <span className="text-light-white">{ item.BuyQuantity }</span>
                                                                </div>
                                                                <div className="product-name">
                                                                    <span><a href="#" className="text-light-white">
                                                                        { item.DishName } { item.VariantName && ("(" + item.VariantName + ")") }
                                                                        <br /> Addons: <span>{ this.AddOnsList(item.AddOnsArray) }</span>
                                                                    </a></span>
                                                                </div>
                                                            </div>
                                                            <div className="price"> <span className="text-light-white">${ item.FinalPrice }</span>
                                                            </div>
                                                        </div>
                                                    ) }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="payment-method mb-md-40">
                                                    <h5 className="text-light-black fw-600">Payment Method</h5>
                                                    <div className="method-type"> <i className="far fa-credit-card text-dark-white" />
                                                        <span className="text-light-white">Credit Card</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="price-table u-line">
                                                    <div className="item"> <span className="text-light-white">Item subtotal:</span>
                                                        <span className="text-light-white">${ this.state.OrderData.TotalAmount }</span>
                                                    </div>
                                                    <div className="item"> <span className="text-light-white">Processing charge:</span>
                                                        <span className="text-light-white">${ this.state.OrderData.ProcessingCharge }</span>
                                                    </div>

                                                    <div className="item"> <span className="text-light-white">Cook tip:</span>
                                                        <span className="text-light-white">${ this.state.OrderData.Tip }</span>
                                                    </div>
                                                </div>
                                                <div className="total-price padding-tb-10">
                                                    <h5 className="title text-light-black fw-700">Total: <span>${ this.state.OrderData.Grandtotal }</span></h5>
                                                </div>
                                            </div>
                                            {/* <div className="col-12 d-flex"> <a href="#" className="btn-first white-btn fw-600 help-btn">Need Help?</a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* tracking map */ }
                </>
                <Footer></Footer>
            </>
        )
    }
}
