import React, { Component } from 'react'
import { IsUserSignIn } from '../../GlobalFactory';

export default class Cart extends Component {

    constructor (props) {
        super(props);

        this.state = {
            CartOrder: {
                CustomerId: 1,
                CookId: this.id,
                TotalQty: 0,
                TotalAmount: 0,
                PaymnetMode: "cash",
                PaymentStatus: "Success",
                TransactionNumber: "ABCD1234",
                OrderStatusId: 1,
                ExtraNotes: "no onion & garlic",
                tblOrderChilds:
                    [
                        // {
                        //     DishId: "95",
                        //     CookId: "70",
                        //     DishName: "South Inidian",
                        //     CuisineId: "114",
                        //     CuisineName: "Cuisine name",
                        //     FoodTypeId: 164,
                        //     FoodTypeName: "Food Type NAme",
                        //     FoodMealTypeId: 5,
                        //     FoodMealTypeName: "Food meal type name",
                        //     FoodCategoryTypeId: 21,
                        //     FoodCategoryTypeName: "food category id",
                        //     AllergensId: 11,
                        //     AllergensName: "allergence name",
                        //     Photo: "/Resources/CookDishPhotos/70_637406652205624664.jpg",
                        //     TotalCalories: 1500,
                        //     DishVariantId: 161,
                        //     VariantName: "Double",
                        //     AddOnsArray: "[]",
                        //     AddOnsTotal: 10,
                        //     Price: 13,
                        //     Discount: 1,
                        //     PackingCharges: 1,
                        //     FinalPrice: 13.87,
                        //     Quantity: "1",
                        //     QuantityType: "PKG",
                        //     BuyQuantity: 2,
                        //     FinalTotalPerQuantity: 27,
                        //     FinalTotalWithAddOns: 37,
                        // }
                    ]
            }
        }

        this._RemoveItem = this.RemoveItem.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        var CartOrder = (localStorage.getItem("CartOrder")) ? JSON.parse(localStorage.getItem("CartOrder")) : state.CartOrder;

        if (CartOrder !== state.CartOrder) {
            return {
                CartOrder: CartOrder,
            };
        }
        // Return null to indicate no change to state.
        return null;
    }


    RemoveItem(item) {

        if (window.confirm("Do you want to this remove dish?")) {


            var CartOrder = this.state.CartOrder;
            console.log("delete before")
            console.log(CartOrder)
            console.log(item)

            let tblOrderChilds = CartOrder.tblOrderChilds;
            let TotalAmount = 0;
            let TotalQty = 0
            console.log(tblOrderChilds)
            tblOrderChilds = tblOrderChilds.filter((e) => (e.UID !== item.UID))

            console.log("Filter data")
            console.log(tblOrderChilds)

            tblOrderChilds.map((item, index) => {
                TotalAmount = parseFloat(TotalAmount) + parseFloat(item.FinalTotalWithAddOns);
                TotalQty = parseInt(TotalQty) + parseInt(item.BuyQuantity);
            })

            CartOrder.tblOrderChilds = tblOrderChilds;
            CartOrder.TotalAmount = TotalAmount;
            CartOrder.TotalQty = TotalQty;

            CartOrder.ProcessingCharge = 5;
            CartOrder.Tip = 0;
            CartOrder.Grandtotal = CartOrder.TotalAmount + CartOrder.ProcessingCharge + CartOrder.Tip;

            console.log("after filter")
            console.log(CartOrder)

            localStorage.setItem("CartOrder", JSON.stringify(CartOrder))
            this.setState({ CartOrder: CartOrder }, () => {
                // console.log(this.state.CartOrder);
            }
            )

        }
    }

    FinalCart() {

        console.log(this.state.CartOrder)
        let CartOrder = this.state.CartOrder;
        let tblOrderChilds = this.state.CartOrder.tblOrderChilds;
        let TotalAmount = 0;
        let TotalQty = 0
        tblOrderChilds.map((item, index) => {
            TotalAmount = TotalAmount + parseFloat(item.FinalTotalWithAddOns);
            TotalQty = parseInt(TotalQty) + parseInt(item.BuyQuantity);
        })
        CartOrder.TotalAmount = TotalAmount;
        CartOrder.TotalQty = TotalQty;

        return (
            <div className="card-body no-padding" id="scrollstyle-4">
                { tblOrderChilds.map((item, index) =>
                    <div className="cat-product-box">
                        <div className="cat-product">
                            <div className="cat-name">
                                <a href="#">
                                    <p className="text-light-green fw-700"><span className="text-dark-white">{ index + 1 }</span> { item.DishName }</p> <span className="text-light-white fw-700">
                                        { item.VariantName }
                                    </span>
                                </a>
                            </div>
                            <div className="delete-btn">
                                <a href="#" className="text-dark-white" onClick={ (e) => this._RemoveItem(e, item) }> <i className="far fa-trash-alt" />
                                </a>
                            </div>

                            <div className="price">
                                <a href="#" className="text-dark-white fw-500">
                                    { item.BuyQuantity }
                                </a>
                            </div>

                            <div className="price">
                                <a href="#" className="text-dark-white fw-500">
                                    ${ item.FinalTotalWithAddOns }
                                </a>
                            </div>

                        </div>
                    </div>
                )
                }
                <div className="item-total">
                    <div className="total-price border-0"> <span className="text-dark-white fw-700">Items subtotal:</span>
                        <span className="text-dark-white fw-700">${ parseFloat(CartOrder.TotalAmount).toFixed(2) }</span>
                    </div>
                    {/* <div className="empty-bag padding-15 fw-700"> <a href="#">Empty bag</a>
                    </div> */}
                </div>
                <div className="card-footer padding-15"> <a href="#" onClick={ () => { IsUserSignIn() } } className="btn-first green-btn text-custom-white full-width fw-500">Proceed to Checkout</a>
                </div>
            </div>
        )
    }

    FinalCart_v2() {
        console.log(this.state.CartOrder)
        let CartOrder = this.state.CartOrder;
        let tblOrderChilds = this.state.CartOrder.tblOrderChilds;
        // let TotalAmount = 0;
        // let TotalQty = 0
        // tblOrderChilds.map((item, index) => {
        //     TotalAmount = TotalAmount + item.FinalTotalWithAddOns;
        //     TotalQty = TotalQty + item.BuyQuantity;
        // })
        // CartOrder.TotalAmount = TotalAmount;
        // CartOrder.TotalQty = TotalQty;

        return (
            <>
                <div className="card-body no-padding" id="scrollstyle-4">
                    <div className="cat-product-box">
                        { tblOrderChilds.map((item, index) =>
                            <div className="cat-product">
                                <div className="cat-name">
                                    <a href="#">
                                        <p className="text-light-green fw-700"><span className="text-dark-white">{ index + 1 }</span> { item.DishName }</p> <span className="text-light-white fw-700">{ item.VariantName }</span>
                                    </a>
                                </div>
                                <div className="delete-btn">
                                    <a href="#" className="text-dark-white" onClick={ (e) => { this.RemoveItem(item) } }> <i className="far fa-trash-alt" />
                                    </a>
                                </div>
                                <div className="price">
                                    <a href="#" className="text-dark-white fw-500">
                                        { item.BuyQuantity }
                                    </a>
                                </div>

                                <div className="price">
                                    <a href="#" className="text-dark-white fw-500">
                                        ${ item.FinalTotalWithAddOns }
                                    </a>
                                </div>
                            </div>
                        )
                        }
                    </div>

                    <div className="item-total">
                        <div className="total-price border-0 pb-0"> <span className="text-dark-white fw-600">Items subtotal:</span>
                            <span className="text-dark-white fw-600">${ parseFloat(CartOrder.TotalAmount).toFixed(2) }</span>
                        </div>

                        { (window.location.href.includes("CheckOut")) &&
                            <>
                                {/* <div className="total-price border-0 pt-0 pb-0"> <span className="text-light-green fw-600">Delivery fee:</span>
                                    <span className="text-light-green fw-600">Free</span>
                                </div> */}
                                <div className="total-price border-0 pt-0 pb-0"> <span className="text-dark-white fw-600">Processing charge:</span>
                                    <span className="text-dark-white fw-600">${ CartOrder.ProcessingCharge }</span>
                                </div>

                                <div className="total-price border-0 pt-0 pb-0"> <span className="text-dark-white fw-600">Tip:</span>
                                    <span className="text-dark-white fw-600">${ CartOrder.Tip }</span>
                                </div>
                                <br></br>
                            </>
                        }
                        {/* <div className="total-price border-0 "> <span className="text-light-black fw-700">Total:</span>
                            <span className="text-light-black fw-700">${ CartOrder.TotalAmount }</span>
                        </div> */}


                    </div>

                </div>
                { (window.location.href.includes("CheckOut")) ?
                    <div className="card-footer p-0 modify-order">
                        <button className="text-custom-white full-width fw-500 bg-light-green" onClick={ () => { window.location.href = "/Restaurant/" + CartOrder.CookId } }><i className="fas fa-chevron-left mr-2" /> Modify Your Order</button>
                        <a href="#" className="total-amount"> <span className="text-custom-white fw-700">TOTAL</span>
                            <span className="text-custom-white fw-700">${ CartOrder.Grandtotal }</span>
                        </a>

                    </div>
                    :
                    <div className="card-footer padding-15">
                        { CartOrder.Grandtotal > 0 && <a href="#" onClick={ () => { IsUserSignIn() } } className="btn-first green-btn text-custom-white full-width fw-500">Proceed to Checkout</a> }
                    </div>
                }
            </>
        )

    }
    render() {
        return (
            <>
                <div className="sidebar">
                    <div className="cart-detail-box">
                        <div className="card">
                            <div className="card-header padding-15 fw-700">Your order from
                            <p className="text-light-white no-margin fw-500"></p>
                            </div>
                            {
                                this.FinalCart_v2()
                            }
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
