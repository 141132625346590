import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import dashboard from './Modules/Default/index';
import Login from './Modules/Login/LoginContent'
import Registration from './Modules/Defaultregister/Defaultregister'
import Restaurent from './Modules/Restaurent/index';
import CustomerProfile from './Modules/Customer/CustomerProfile'
import CheckOut from './Modules/CheckOut/CheckOut'
import demo from './Modules/Demo/demo';
import OrderDetails from './Modules/Order/OrderDetails';
import OrderList from './Modules/Order/OrderList';
import OrderDetailsReport from './Modules/Order/OrderDetailsReport';
import VerifyUser from './Modules/EmailVarification/EmailVarification';

/* ========================  Project Link  ========================================*/


/* ========================  Project Link  End ========================================*/


function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={ dashboard } />
          <Route path="/Login" component={ Login }></Route>
          <Route path="/Registration" component={ Registration }></Route>
          <Route path="/Restaurant/:id" component={ Restaurent }></Route>
          <Route path="/CustomerProfile" component={ CustomerProfile }></Route>
          <Route path="/CheckOut" component={ CheckOut }></Route>
          <Route path="/OrderList" component={ OrderList }></Route>
          <Route path="/OrderDetails/:id" component={ OrderDetails }></Route>
          <Route path="/OrderDetailsReport/:id" component={ OrderDetailsReport }></Route>
          <Route path="/VerifyUser/:id" component={ VerifyUser }></Route>
          <Route path="/demo" component={ demo }></Route>
        </Switch>
      </Router>
      {/* <LoadCommanJS></LoadCommanJS> */ }
    </>
  );
}

export default App;
