import React from 'react';
import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import "./CheckoutForm.css";
import Footer from '../Footer'
import Header from '../Header'
import Cart from './Cart';
import ApiInstance from '../../Utilities/axiosConfig';
import { GetSessionData, GetUserSearchLocation, SetUserSearchLocation } from '../../GlobalFactory';
import LoaderComponent from '../../Components/LoaderComponent';
import Swal from 'sweetalert2';

export default class CheckoutForm extends React.Component {

    constructor () {
        super();
        this.state = {
            RegistrationData: {},
            ActiveLoader: false,
            RestarentData: {}
        }
    }
    sweetalerthtml(OrderMasterId) {
        Swal.fire({
            title: '<spam>Success!</spam>',
            icon: '',
            html: 'Your payment has been done successfully!',
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            cancelButtonText: 'Close',
            cancelButtonAriaLabel: 'Thumbs down'
        }).then(function (result) {
            if (result.value) {
                window.location.href = "/OrderDetails/" + OrderMasterId;
                //this.resetFormData();
            }
        });

    }

    handleSubmit = async (event) => {
        event.preventDefault();

        const { stripe, elements } = this.props;
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        if (error) {
            console.log(error)
        }
        if (paymentMethod) {
            this.setState({ ActiveLoader: true })
            var CartOrder = JSON.parse(localStorage.getItem("CartOrder"))
            console.log(CartOrder)
            console.log(paymentMethod)

            CartOrder.ExtraNotes = document.getElementById("Instruction").value;
            const formData = new FormData();

            formData.append("Data", JSON.stringify(CartOrder));

            formData.append("paymentMethodId", paymentMethod.id);

            formData.append("CustomerDetails", JSON.stringify(this.state.RegistrationData || ''));

            console.log(JSON.stringify(CartOrder))

            ApiInstance().post("OrderMaster/InserttblOrderMaster", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(response => {
                    let data = response.data;
                    console.log(data);
                    var OrderData = JSON.parse(data.data)

                    if (data.status) {
                        console.log(OrderData.OrderMasterId)
                        localStorage.setItem("CartOrder", "")
                        console.log("/OrderDetails/" + OrderData.OrderMasterId)

                        //window.location.href = "/OrderDetails/" + OrderData.OrderMasterId;
                        this.sweetalerthtml(OrderData.OrderMasterId)
                        // this.setState({ RegistrationData: {}, ActiveLoader: false }
                        //     , () => {
                        //         window.location.href = "/OrderDetails/" + OrderData.OrderMasterId;
                        //     })
                    }

                }, error => {
                    //window.init_PNotify("Error", "error", "Record not updated!!");
                    console.log(error);
                });

        }

    };
    componentDidMount() {

        if (GetSessionData()) {
            this.GetCustomerProfile();
            this.GetRestaurentData();
        }
    }

    GetCustomerProfile() {

        ApiInstance().get('CustomerMaster/SelectCustomerProfileAddressByCustomerId')
            .then(response => {
                var data = response;


                if (data) {


                    let RegistrationData = JSON.parse(data.data.data).Data[0]
                    let tblCustomerAddressMasters = RegistrationData.tblCustomerAddressMasters;

                    console.log(RegistrationData);

                    let HomeAddress = {};

                    if (tblCustomerAddressMasters.length > 0) {
                        HomeAddress = tblCustomerAddressMasters.find(element => element.AddressName.toLowerCase() == 'home')

                    }

                    let OfficeAddress = {};
                    if (tblCustomerAddressMasters.length > 0) {
                        OfficeAddress = tblCustomerAddressMasters.find(element => element.AddressName.toLowerCase() == 'office')

                    }

                    let OtherAddress = {}
                    if (tblCustomerAddressMasters.length > 0) {
                        OtherAddress = tblCustomerAddressMasters.find(element => element.AddressName.toLowerCase() == 'other')

                    }
                    this.setState({
                        RegistrationData: RegistrationData, HomeAddress: HomeAddress, OfficeAddress: OfficeAddress, OtherAddress: OtherAddress
                    });
                }
            }, error => {
                console.log(error);
            });
    }

    GetRestaurentData() {
        var CartOrder = JSON.parse(localStorage.getItem("CartOrder"))
        ApiInstance().get('CustomerHome/SelectCookProfileAndDishListByCookId?CookId=' + CartOrder.CookId)
            .then(response => {
                var data = response.data.data;

                if (data) {

                    let objdata = JSON.parse(data).Data
                    console.log(objdata[0])
                    // let DishList = JSON.parse(objdata[0].DishList)
                    this.setState({
                        RestarentData: objdata[0]
                    });
                }
            }, error => {
                console.log(error);
            });


    }


    render() {

        const options = {
            style: {
                base: {
                    color: "#32325d",
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: "antialiased",
                    fontSize: "16px",
                    "::placeholder": {
                        color: "#aab7c4",
                    },
                },
                invalid: {
                    color: "#fa755a",
                    iconColor: "#fa755a",
                },
            },
        };
        const { stripe } = this.props;
        console.log(this.state.RegistrationData)
        return (

            // <form onSubmit={ this.handleSubmit }>
            //     <div className="sr-combo-inputs">
            //         <div className="sr-combo-inputs-row">
            //             <CardElement className="sr-input sr-card-element" options={ options } />
            //         </div>
            //         <button type="submit" disabled={ !stripe } className="btn">
            //             Pay
            //     </button>
            //     </div>
            // </form>
            <>
                <LoaderComponent ActiveLoader={ this.state.ActiveLoader }></LoaderComponent>
                <Header />
                {/* Navigation */ }
                <section className="final-order section-padding bg-light-theme">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="main-box padding-20">
                                    <div className="row mb-xl-20">
                                        <div className="col-md-6">
                                            <div className="section-header-left">
                                                <h3 className="text-light-black header-title fw-700">Review and place order</h3>
                                            </div>
                                            <h6 className="text-light-black fw-700 fs-14">Review address, payments, and tip to complete your purchase</h6>
                                            <h6 className="text-light-black fw-700 mb-2"></h6>
                                            {/* <p className="text-light-green fw-600">Delivery, ASAP (60-70m)</p> */ }
                                            <p className="text-light-white title2 mb-1">
                                                { this.state.RegistrationData.FirstName || "" } { this.state.RegistrationData.LastName || "" }
                                                {/* <span><a href="#">Change Details</a></span> */ }
                                            </p>
                                            <p className="text-light-black fw-600 mb-1">{ this.state.RestarentData.BussinessName || '' }</p>
                                            <p className="text-light-white mb-1">{ this.state.RestarentData.Address1 || '' }<br /></p>
                                            <p className="text-light-white">Phone: { this.state.RestarentData.BussinessMobileNo || '' }</p>
                                            <p className="text-light-white">Email: { this.state.RestarentData.BussinessEmail || '' }</p>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="advertisement-img">
                                                {/* <img src="https://via.placeholder.com/670x300" className="img-fluid full-width" alt="advertisement-img" /> */ }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <form onSubmit={ this.handleSubmit }>
                                                <div className="payment-sec">
                                                    <div className="section-header-left">
                                                        <h3 className="text-light-black header-title">Additional Instructions</h3>
                                                    </div>
                                                    <div className="form-group">
                                                        <textarea className="form-control form-control-submit" rows={ 4 } placeholder="Additional Instructions" id="Instruction" name="Instruction" defaultValue={ "" } maxLength="250" />
                                                    </div>
                                                    {/* <div className="form-group">
                                                    <label className="custom-checkbox">
                                                        <input type="checkbox" name="#" /> <span className="checkmark" /> Spare me the napkins and plasticware. I'm trying to save the earth.</label>
                                                </div> */}
                                                    <div className="section-header-left">
                                                        <h3 className="text-light-black header-title">Payment information</h3>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div id="accordion">
                                                                {/* <div className="card">
                                                                <div className="card-header"> <a className="collapsed card-link fw-500 fs-14" data-toggle="collapse" href="#collapseOne">
                                                                    Pay with a Gift Card
                                                                                     </a>
                                                                </div>
                                                                <div id="collapseOne" className="collapse" data-parent="#accordion">
                                                                    <div className="card-body no-padding payment-option-tab">
                                                                        <div className="form-group">
                                                                            <div className="credit-card gift-card p-relative">
                                                                                <input type="text" name="#" className="form-control-submit fs-16" defaultValue="AC2B76" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                                {/* <div className="card">
                                                                <div className="card-header"> <a className="collapsed card-link fw-500 fs-14" data-toggle="collapse" href="#collapseTwo">
                                                                    Add a promo code
                                                                         </a>
                                                                </div>
                                                                <div id="collapseTwo" className="collapse" data-parent="#accordion">
                                                                    <div className="card-body no-padding payment-option-tab">
                                                                        <div className="form-group">
                                                                            <div className="credit-card promocode p-relative input-group">
                                                                                <input type="text" name="#" className="form-control-submit fs-16" placeholder="AC2B76" />
                                                                                <button type="submit" className="btn-second btn-submit ml-1">Apply</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                                <div className="payment-option-tab">

                                                                    <div className="tab-pane" id="newcreditcard">
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="form-group">
                                                                                    <label className="text-light-white fw-700">Card Details</label>
                                                                                    <div className="p-relative">
                                                                                        <CardElement className="form-control-submit fs-16 sr-input sr-card-element" options={ options } />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="col-md-2">
                                                                                <div className="form-group">
                                                                                    <label className="text-light-white fw-700">Expires on</label>
                                                                                    <input type="text" name="#" className="form-control form-control-submit" placeholder="12/21" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <div className="form-group">
                                                                                    <label className="text-light-white fw-700">Security Code</label>
                                                                                    <div className="credit-card card-back p-relative">
                                                                                        <input type="text" name="#" className="form-control form-control-submit" placeholder={ 123 } />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <div className="form-group">
                                                                                    <label className="text-light-white fw-700">ZIP Code</label>
                                                                                    <input type="text" name="#" className="form-control form-control-submit" placeholder={ 123456 } />
                                                                                </div>
                                                                            </div> */}
                                                                            <div className="col-12">
                                                                                <div className="form-group">
                                                                                    {/* <label className="custom-checkbox">
                                                                                        <input type="checkbox" name="#" /> <span className="checkmark" />
                                                                                        Save Credit card</label> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="section-header-left">
                                                                        <h3 className="text-light-black header-title">Add a tip for your driver</h3>
                                                                    </div>
                                                                    <div className="driver-tip-sec mb-xl-20">
                                                                        <ul className="nav nav-tabs">
                                                                            <li className="nav-item"> <a className="nav-link fw-600 active" data-toggle="tab" href="#tipnewcard">Tip with Credit Card</a>
                                                                            </li>
                                                                            <li className="nav-item"> <a className="nav-link fw-600 disabled" data-toggle="tab" href="#newcashtip">Tip with Cash</a>
                                                                            </li>
                                                                        </ul>
                                                                        <div className="tab-content">
                                                                            <div className="tab-pane active" id="tipnewcard">
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <div className="tip-percentage">
                                                                                            <form>
                                                                                                <label className="radio-inline text-light-green fw-600">
                                                                                                    <input type="radio" name="tip-percentage" defaultChecked /> <span>15%</span>
                                                                                                </label>
                                                                                                <label className="radio-inline text-light-green fw-600">
                                                                                                    <input type="radio" name="tip-percentage" /> <span>25%</span>
                                                                                                </label>
                                                                                                <label className="radio-inline text-light-green fw-600">
                                                                                                    <input type="radio" name="tip-percentage" /> <span>25%</span>
                                                                                                </label>
                                                                                                <label className="radio-inline text-light-green fw-600">
                                                                                                    <input type="radio" name="tip-percentage" /> <span>30%</span>
                                                                                                </label>
                                                                                            </form>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <div className="custom-tip">
                                                                                            <div className="input-group mb-3">
                                                                                                <div className="input-group-prepend"> <span className="input-group-text text-light-green fw-500">Custom tip</span>
                                                                                                </div>
                                                                                                <input type="text" className="form-control form-control-submit" placeholder="Custom tip" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="section-header-left">
                                                                        <h3 className="text-light-black header-title">Donate the change</h3>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="custom-checkbox">
                                                                            <input type="checkbox" name="#" /> <span className="checkmark" />
                                      Donate $0.77 to No kid Hungry. By checking this box you agree to the Donate the Change <a href="#">Terms of use</a>  <span className="ml-2"><a href="#">Learn More</a></span>
                                                                        </label>
                                                                    </div>
                                                                    */}
                                                                        <div className="form-group">
                                                                            <button type="submit" className="btn-first green-btn text-custom-white full-width fw-500">Place Your Order</button>
                                                                        </div>
                                                                        <p className="text-center text-light-black no-margin">By placing your order, you agree to Munchbox's <a href="#">terms of use</a> and <a href="#">privacy agreement</a>
                                                                        </p>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <Cart></Cart>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </>

        );
    }
}


