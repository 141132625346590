import React, { Component } from 'react'
import { geolocated } from "react-geolocated";
import { GetUserSearchLocation, SetUserSearchLocation } from '../GlobalFactory';

class GetGEOLocation extends React.Component {

    constructor (props) {
        super(props);


    }

    componentDidMount() {
        localStorage.removeItem("UserSearchLocation")
        let UserSearchLocation = GetUserSearchLocation();
        console.log(this.props)
        if (this.props.coords !== null && String(UserSearchLocation.Latitude).length < 2) {
            SetUserSearchLocation(this.props.coords.latitude, this.props.coords.longitude, '', 5);
            window.location.reload();
        }
    }

    render() {
        return !this.props.isGeolocationAvailable ? (
            <div>Your browser does not support Geolocation</div>
        ) : !this.props.isGeolocationEnabled ? (
            <div>Geolocation is not enabled</div>
        ) : this.props.coords ? (
            // <table>
            //     <tbody>
            //         <tr>
            //             <td>latitude</td>
            //             <td>{ this.props.coords.latitude }</td>
            //         </tr>
            //         <tr>
            //             <td>longitude</td>
            //             <td>{ this.props.coords.longitude }</td>
            //         </tr>
            //         <tr>
            //             <td>altitude</td>
            //             <td>{ this.props.coords.altitude }</td>
            //         </tr>
            //         <tr>
            //             <td>heading</td>
            //             <td>{ this.props.coords.heading }</td>
            //         </tr>
            //         <tr>
            //             <td>speed</td>
            //             <td>{ this.props.coords.speed }</td>
            //         </tr>
            //     </tbody>
            // </table>
            <></>
        ) : (
                        <></>
                    );
    }
}
export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(GetGEOLocation);
