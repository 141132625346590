import React, { Component } from 'react'
import Footer from '../Footer';
import Header from '../Header';
import ApiInstance from '../../Utilities/axiosConfig';
import Config from '../../Config';
import { GetSessionData, TodaysDate, DateFromLastMonth } from '../../GlobalFactory';

export default class OrderList extends Component {
    constructor () {
        super();

        this.state = {
            OrderList: []
        }

    }


    componentDidMount() {


        this.GetOrderList();
    }


    GetOrderList = () => {
        var today = new Date()

        // var dtto = (document.getElementById("dtTo").value) ? document.getElementById("dtTo").value : today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        // var dtfrom = (document.getElementById("dtFrom").value) ? document.getElementById("dtFrom").value : today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

        var dtfrom = (document.getElementById("dtFrom").value) ? document.getElementById("dtFrom").value : (DateFromLastMonth().PrevMonthdate_YYYY_MMM_DD);
        var dtto = (document.getElementById("dtTo").value) ? document.getElementById("dtTo").value : (TodaysDate().Todaydate_YYYYMMMDD);

        let jsonobj = {
            StartDate: dtfrom,
            EndDate: dtto,
            CustomerId: GetSessionData().UserId
        };

        console.log(jsonobj);

        ApiInstance().post('Report/ReportCustomerOrderMasterByCustomerId', jsonobj, { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                var data = response.data;
                if (data) {

                    var objdata = JSON.parse(data.data)
                    console.log(objdata.Data)


                    this.setState({
                        OrderList: objdata.Data

                    });
                }
            }, error => {
                console.log(error);
            });
    }

    render() {
        return (
            <>
                <Header></Header>
                {/* Browse by category */ }
                <div className="most-popular section-padding">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-2 browse-cat border-0"></div>
                            <div className="col-lg-8 browse-cat border-0">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="sort-tag-filter padding-15">

                                            <div className="sorting"> <span className="text-dark-white fs-16 fw-600">Order history: </span>
                                                {/* <select>
                                                    <option>Default</option>
                                                    <option>Price</option>
                                                    <option>Rating</option>
                                                    <option>Distance</option>
                                                </select> */}


                                                {/* Hello world */ }
                                                <form className="form-inline">
                                                    <div className="form-group">
                                                        <label htmlFor="email"><span className="text-dark-white fs-16 fw-600">From: </span></label>
                                                        <input type="date" className="form-control" id="dtFrom" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="pwd"><span className="text-dark-white fs-16 fw-600"> &nbsp;&nbsp;To: </span></label>
                                                        <input type="date" className="form-control" id="dtTo" />
                                                    </div>
                                                    &nbsp;&nbsp;&nbsp;<button type="button" onClick={ () => { this.GetOrderList() } } className="btn-second btn-submit">Submit</button>
                                                </form>


                                            </div>
                                        </div>

                                        {
                                            this.state.OrderList.map((item, index) =>
                                                <div className="product-list-view" key={ item.OrderMasterId }>
                                                    <div className="product-list-info">
                                                        <div className="product-list-img">
                                                            <a href="restaurant.html">
                                                                <img style={ { maxWidth: "90px", maxHeight: "90px" } } src={ (item.ProfilePhoto) ? (Config.BaseURL + item.ProfilePhoto) : "https://via.placeholder.com/90" } className="img-fluid" alt="#" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="product-right-col">
                                                        <div className="product-list-details">
                                                            <div className="product-list-title">
                                                                <div className="product-info">
                                                                    <h6><a href={ "/Restaurant/" + item.CookId } className="text-light-black fw-600">{ item.BussinessName }</a></h6>
                                                                    <p className="text-light-white fs-12">{ item.OrderInsertDateTime }</p>
                                                                </div>
                                                            </div>
                                                            <div className="product-detail-right-box">
                                                                {/* <div className="product-list-rating text-center">
                                                            <div className="ratings"> <span className="text-yellow"><i className="fas fa-star" /></span>
                                                                <span className="text-yellow"><i className="fas fa-star" /></span>
                                                                <span className="text-yellow"><i className="fas fa-star" /></span>
                                                                <span className="text-yellow"><i className="fas fa-star" /></span>
                                                                <span className="text-yellow"><i className="fas fa-star-half-alt" /></span>
                                                            </div>
                                                            <div className="rating-text">
                                                                <p className="text-light-white fs-12">3845 ratings</p>
                                                            </div>
                                                        </div> */}
                                                                <div className="product-list-tags">
                                                                    {/* <span className="text-custom-white rectangle-tag bg-gradient-red">
                                                                10%
                                                            </span> */}
                                                                    <span className="rectangle-tag bg-gradient-green text-custom-white">
                                                                        { item.OrderStatus }
                                                                    </span>
                                                                </div>

                                                                <div className="product-list-label">
                                                                    &nbsp;&nbsp;Qty
                                                                    <span className="circle-tag">
                                                                        { item.TotalQty }
                                                                    </span>

                                                                </div>
                                                                <div className="product-list-price">
                                                                    <div className="price">
                                                                        <h6 className="text-light-black no-margin">${ item.TotalAmount }</h6>
                                                                        <span className="text-light-white"></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="product-list-bottom">
                                                            <div className="product-list-type">
                                                                <span className="text-light-white new">TransactionNumber: { item.TransactionNumber }</span><br></br>

                                                                {/* <span className="text-custom-white square-tag">
                                                            <img src="assets/img/svg/004-leaf.svg" alt="tag" />
                                                        </span>
                                                        <span className="text-custom-white square-tag">
                                                            <img src="assets/img/svg/006-chili.svg" alt="tag" />
                                                        </span>
                                                        <span className="text-custom-white square-tag">
                                                            <img src="assets/img/svg/005-chef.svg" alt="tag" />
                                                        </span>
                                                        <span className="text-custom-white square-tag">
                                                            <img src="assets/img/svg/008-protein.svg" alt="tag" />
                                                        </span>
                                                        <span className="text-custom-white square-tag">
                                                            <img src="assets/img/svg/009-lemon.svg" alt="tag" />
                                                        </span> */}
                                                            </div>

                                                            <div className="product-list-time">
                                                                <span >
                                                                    {/* <img src="assets/img/svg/013-heart-1.svg" alt="tag" /> */ }
                                                                    &nbsp;
                                                                </span>
                                                                <span>

                                                                    <a href={ "/OrderDetails/" + item.OrderMasterId }><b>View Details</b></a>
                                                                </span>
                                                                {/* <ul>
                                                                    <li className="text-light-white">&nbsp;</li>
                                                                    <li className="text-light-white">
                                                                      <a href={ "/OrderDetails/" + item.OrderMasterId }>View Details</a> 
                                                                    </li>
                                                                </ul> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }


                                        {/* <div className="custom-pagination">
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Previous"> <span aria-hidden="true">«</span>
                                                            <span className="sr-only">Previous</span>
                                                        </a>
                                                    </li>
                                                    <li className="page-item"><a className="page-link" href="#">1</a>
                                                    </li>
                                                    <li className="page-item active"><a className="page-link" href="#">2</a>
                                                    </li>
                                                    <li className="page-item"><a className="page-link" href="#">3</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Next"> <span aria-hidden="true">»</span>
                                                            <span className="sr-only">Next</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 browse-cat border-0">
                            </div>
                        </div>
                    </div>
                </div>
                {/* Browse by category */ }
                <Footer></Footer>
            </>
        )
    }
}
