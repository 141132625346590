import React, { Component } from 'react'
import Header from '../Header';
import Footer from '../Footer'
import { GetSessionData, GetUserSearchLocation, SetUserSearchLocation } from '../../GlobalFactory';
import ApiInstance from '../../Utilities/axiosConfig';
import Config from '../../Config';
import GetGEOLocation from '../../Modules/GetGEOLocation';
import PlacesAutoComplete from '../../Components/PlaceAutoComplete'

export default class index extends Component {
    constructor () {
        super()
        this.state = {
            RestaurentList: [], SearchText: '', RestaurentMasterList: []

        }
        this.SearchKeyList = []
        this.refHeader = React.createRef();

    }

    componentDidMount() {

        console.log(document.getElementById("txtSearch"))
    }

    SetCurrentLocation = (place) => {
        console.log(place)
        // let UserSearchLocation = GetUserSearchLocation()
        const currentState = this.state.openSearch;
        SetUserSearchLocation(place.geometry.location.lat(), place.geometry.location.lng(), place.formatted_address, this.state.Radius)
        this.setState({ Locationlabel: place.formatted_address, openSearch: !currentState })

        this.GetRestaurentList(this.state.SearchText);
        this.refHeader.current.SetCurrentSearcAddress(place)
    }

    SetRadius(event) {
        const value = event.target.value;
        const key = event.target.name;
        console.log(value)
        if (value > 4 && value <= 60) {
            SetUserSearchLocation('', '', '', value)

            if (this.props.GetRestaurentList) this.props.GetRestaurentList(this.state.SearchText)
            this.setState({ Radius: value })
        }
    }

    GetRestaurentList = (searchtext = '') => {

        let UserSearchLocation = GetUserSearchLocation();

        console.log(UserSearchLocation)
        let CustomerHomeDTO = {

            "CenterLatitude": UserSearchLocation.Latitude,
            "CenterLongitude": UserSearchLocation.Longitude,
            "RadiusInmiles": UserSearchLocation.Radius

        }
        console.log(CustomerHomeDTO)

        ApiInstance().post('CustomerHome/SelectAllCookProfilesForHomePageUsingLatLongAndDistance', CustomerHomeDTO)
            .then(response => {
                console.log(response)
                var data = response.data.data;
                if (data) {
                    let dataObject = JSON.parse(data).Data;
                    this.setState({ RestaurentList: dataObject, RestaurentMasterList: dataObject },
                        () => {
                            this.SearchTextBoxEventCall()
                        })
                }
                else {
                }
            }, error => {
                console.log(error);
            });


    }

    SearchRestaurentList = (searchtext = '') => {
        let dataObject = this.state.RestaurentMasterList;
        //  console.log(dataObject);
        if (searchtext !== '') {
            var filterdata = dataObject.filter((item) => (item.Keyword) ? item.Keyword.toUpperCase().includes(searchtext.toUpperCase()) : '')
            dataObject = filterdata;
            console.log(dataObject)
            this.setState({ RestaurentList: dataObject })

        }
        else {
            this.setState({ RestaurentList: dataObject })
        }
    }

    generateStar(AvgRating) {
        const list = [];

        for (var i = 0; i < AvgRating; i++) {
            list.push(<i className="fas fa-star text-yellow"></i>)
        }

        for (var i = 0; i < (5 - AvgRating); i++) {
            list.push(<i className="fas fa-star text-dark-white"></i>)
        }
        return list;
    }

    RenderRestaurentList() {
        var imgcss = {

            "maxWidth": "350px",
            "maxHeight": "205px",
            "width": "100%",
            "height": "100%",
        }
        console.log(this.state.RestaurentList)
        return (


            <>
                {/* <GetGEOLocation /> */ }
                {
                    this.state.RestaurentList.map((item, index) =>
                        <div className="col-lg-4 col-md-6 col-sm-6 overflow-hidden position-relative" key={ index }>
                            { !item.IsCookOnline && <div className="overlay-div">
                            </div>
                            }
                            <div className="product-box mb-xl-20">
                                <div className="product-img" onClick={ () => { window.location.href = "/Restaurant/" + item.CookId } } style={ { cursor: 'pointer' } }>
                                    <a href={ "/Restaurant/" + item.CookId }>
                                        <img src={ ((item.ProfilePhoto) ? (Config.BaseURL + item.ProfilePhoto) : "https://via.placeholder.com/255x150") } className="img-fluid full-width img-ctrl" alt="product-img" />
                                    </a>
                                    {/* <div className="overlay">
                                        <div className="product-tags padding-10"> <span className="circle-tag">
                                            <img src="assets/img/svg/013-heart-1.svg" alt="tag" />
                                        </span>
                                            <div className="custom-tag"> <span className="text-custom-white rectangle-tag bg-gradient-red">
                                                10%
                                        </span>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="product-caption">
                                    <div className="title-box">
                                        <h6 className="product-title"><a href={ "/Restaurant/" + item.CookId } className="text-light-black "> { item.BussinessName }</a></h6>
                                        <div className="tags"> <span className="text-custom-white rectangle-tag bg-yellow">
                                            3.1
                                                                            </span>
                                        </div>
                                    </div>
                                    <p className="text-light-white">{ item.CuisineType }</p>
                                    <div className="product-details">
                                        <div className="price-time"> <span className="text-light-black time">{ item.FarAway } Miles</span>
                                            {/* <span className="text-light-white price">$10 min</span> */ }
                                        </div>
                                        <div className="rating"> <span>

                                            { this.generateStar(item.AvgRating) }

                                        </span>
                                            {/* <span className="text-light-white text-right">4225 ratings</span> */ }
                                        </div>
                                    </div>
                                    {/* <div className="product-footer"> <span className="text-custom-white square-tag">
                                    <img src="assets/img/svg/004-leaf.svg" alt="tag" />
                                </span>
                                    <span className="text-custom-white square-tag">
                                        <img src="assets/img/svg/006-chili.svg" alt="tag" />
                                    </span>
                                    <span className="text-custom-white square-tag">
                                        <img src="assets/img/svg/005-chef.svg" alt="tag" />
                                    </span>
                                    <span className="text-custom-white square-tag">
                                        <img src="assets/img/svg/008-protein.svg" alt="tag" />
                                    </span>
                                    <span className="text-custom-white square-tag">
                                        <img src="assets/img/svg/009-lemon.svg" alt="tag" />
                                    </span>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    )
                }

            </>
        )

    }

    SearchTextBoxEventCall() {
        this.state.RestaurentMasterList.map((item, index) => {
            if (item.Keyword) {
                var array = item.Keyword.split(',');
                array.map((callbackfn) => {
                    if (callbackfn.trim().length > 0) this.SearchKeyList.push(callbackfn.trim())
                });
                this.SearchKeyList = this.SearchKeyList.filter(window.onlyUnique)
            }

        });
        window.autocomplete(document.getElementById("txtSearch"), Config.BaseURL, this.SearchKeyList, this.state.RestaurentMasterList);
    }


    render() {

        return (
            <>
                <Header GetRestaurentList={ this.GetRestaurentList } SearchRestaurentList={ this.SearchRestaurentList } RestaurentMasterList={ this.state.RestaurentMasterList } ref={ this.refHeader }></Header>


                <section className="banner-1 p-relative">
                    <img src="/assets/img/banner-6.jpg" className="img-fluid full-width" alt="Banner" />
                    <div className="transform-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6  padding-right"  >
                                    <h1 className="text-light-black fw-700 padding-20" >
                                        <span style={ { color: "#fff" } }> Welcome to</span> <br />
                                        <span style={ { color: "#ff5757" } }>ChefMeF</span>
                                    </h1>
                                </div>
                                <div className="col-lg-6">
                                    <div className="content-wrapper bg-white padding-20">
                                        <div className="content-box padding-tb-10">
                                            <h2 className="text-light-black fw-700">Find any restaurants near you and order online for free.
                                            </h2>


                                            <div className="row">
                                                <div className="col-md-9">
                                                    <label className="text-light-black fw-700">Find a location near you</label>
                                                    <PlacesAutoComplete SetPlace={ this.SetCurrentLocation }></PlacesAutoComplete>
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="text-light-black fw-700">Radius</label>

                                                    <input type="number" id="txtRadiusInmiles" value={ this.state.Radius || 5 } className="form-control w-100" min="0" max="60" style={ { width: "50%" } } name="txtRadiusInmiles" placeholder=" Radius in miles" onChange={ (e) => { this.SetRadius(e) } }></input>
                                                </div>

                                                {/* <input type="search" className="form-control form-control-submit"
                                                        placeholder="Enter street address or zip code" defaultValue="1246 57th St, Brooklyn, NY, 11219" /> */}

                                                {/* <div className="input-group-prepend">
                                                        <button className="input-group-text text-light-green"><i className="fab fa-telegram-plane"></i>
                                                        </button>
                                                    </div> */}

                                                {/* <div className="input-group-append col-xl-4">
                                                    <button className="btn-second btn-submit full-width" type="button">Find food</button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="overlay overlay-bg"></div>
                </section>

                <section className="browse-cat u-line section-padding" style={ { display: "none" } }>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="section-header-left">
                                    <h3 className="text-light-black header-title title">Browse by cuisine <span className="fs-14"><a
                                        href="restaurant.html">See all restaurant</a></span></h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="category-slider swiper-container">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <a href="restaurant.html" className="categories">
                                                <div className="icon icon-parent text-custom-white bg-light-green"> <i className="fas fa-map-marker-alt"></i>
                                                </div> <span className="text-light-black cat-name">Brooklyn</span>
                                            </a>
                                        </div>
                                        <div className="swiper-slide">
                                            <a href="restaurant.html" className="categories">
                                                <div className="icon text-custom-white bg-light-green ">
                                                    <img src="https://via.placeholder.com/125" className="rounded-circle" alt="categories" />
                                                </div> <span className="text-light-black cat-name">Italian </span>
                                            </a>
                                        </div>
                                        <div className="swiper-slide">
                                            <a href="restaurant.html" className="categories">
                                                <div className="icon text-custom-white bg-light-green ">
                                                    <img src="https://via.placeholder.com/125" className="rounded-circle" alt="categories" />
                                                </div> <span className="text-light-black cat-name">Thai </span>
                                            </a>
                                        </div>
                                        <div className="swiper-slide">
                                            <a href="restaurant.html" className="categories">
                                                <div className="icon text-custom-white bg-light-green ">
                                                    <img src="https://via.placeholder.com/125" className="rounded-circle" alt="categories" />
                                                </div> <span className="text-light-black cat-name">Chinese </span>
                                            </a>
                                        </div>
                                        <div className="swiper-slide">
                                            <a href="restaurant.html" className="categories">
                                                <div className="icon text-custom-white bg-light-green ">
                                                    <img src="https://via.placeholder.com/125" className="rounded-circle" alt="categories" />
                                                </div> <span className="text-light-black cat-name">Mexican </span>
                                            </a>
                                        </div>
                                        <div className="swiper-slide">
                                            <a href="restaurant.html" className="categories">
                                                <div className="icon text-custom-white bg-light-green ">
                                                    <img src="https://via.placeholder.com/125" className="rounded-circle" alt="categories" />
                                                </div> <span className="text-light-black cat-name">Indian </span>
                                            </a>
                                        </div>
                                        <div className="swiper-slide">
                                            <a href="restaurant.html" className="categories">
                                                <div className="icon text-custom-white bg-light-green ">
                                                    <img src="https://via.placeholder.com/125" className="rounded-circle" alt="categories" />
                                                </div> <span className="text-light-black cat-name">Lebanese </span>
                                            </a>
                                        </div>
                                        <div className="swiper-slide">
                                            <a href="restaurant.html" className="categories">
                                                <div className="icon text-custom-white bg-light-green ">
                                                    <img src="https://via.placeholder.com/125" className="rounded-circle" alt="categories" />
                                                </div> <span className="text-light-black cat-name">Japanese </span>
                                            </a>
                                        </div>
                                        <div className="swiper-slide">
                                            <a href="restaurant.html" className="categories">
                                                <div className="icon text-custom-white bg-light-green ">
                                                    <img src="https://via.placeholder.com/125" className="rounded-circle" alt="categories" />
                                                </div> <span className="text-light-black cat-name">American </span>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="swiper-button-next"></div>
                                    <div className="swiper-button-prev"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* <section className="recent-order section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="section-header-left">
                                    <h3 className="text-light-black header-title title">Your previous orders <span className="fs-14"><a
                                        href="order-details.html">See all past orders</a></span></h3>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="product-box mb-md-20">
                                    <div className="product-img">
                                        <a href="restaurant.html">
                                            <img src="https://via.placeholder.com/255x104" className="img-fluid full-width" alt="product-img" />
                                        </a>
                                    </div>
                                    <div className="product-caption">
                                        <h6 className="product-title"><a href="restaurant.html" className="text-light-black "> Chilli Chicken Pizza</a>
                                        </h6>
                                        <p className="text-light-white">Big Bites, Brooklyn</p>
                                        <div className="product-btn">
                                            <a href="order-details.html" className="btn-first white-btn full-width text-light-green fw-600">Track
                  Order</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="product-box mb-md-20">
                                    <div className="product-img">
                                        <a href="restaurant.html">
                                            <img src="https://via.placeholder.com/255x104" className="img-fluid full-width" alt="product-img" />
                                        </a>
                                    </div>
                                    <div className="product-caption">
                                        <h6 className="product-title"><a href="restaurant.html" className="text-light-black "> Hakka Noodles</a></h6>
                                        <p className="text-light-white">Flavor Town, Brooklyn</p>
                                        <div className="product-btn">
                                            <a href="order-details.html" className="btn-first white-btn full-width text-light-green fw-600">Track
                  Order</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="product-box mb-md-20">
                                    <div className="product-img">
                                        <a href="restaurant.html">
                                            <img src="https://via.placeholder.com/255x104" className="img-fluid full-width" alt="product-img" />
                                        </a>
                                    </div>
                                    <div className="product-caption">
                                        <h6 className="product-title"><a href="restaurant.html" className="text-light-black "> Vegan Burger </a></h6>
                                        <p className="text-light-white">Great Burger, Brooklyn</p>
                                        <div className="product-btn">
                                            <a href="order-details.html" className="btn-first white-btn full-width text-light-green fw-600">Track
                  Order</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="product-box mb-md-20">
                                    <div className="product-img">
                                        <a href="restaurant.html">
                                            <img src="https://via.placeholder.com/255x104" className="img-fluid full-width" alt="product-img" />
                                        </a>
                                    </div>
                                    <div className="product-caption">
                                        <h6 className="product-title"><a href="restaurant.html" className="text-light-black "> Sticky Date Cake</a></h6>
                                        <p className="text-light-white">Smile N’ Delight, Brooklyn</p>
                                        <div className="product-btn">
                                            <a href="order-details.html" className="btn-first white-btn full-width text-light-green fw-600">Track
                  Order</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                {/* <div className="container">

                    <div className="row">
                        <div className="col-12">
                            <div id="banner-adv2" className="banner-adv2">
                                <img src="https://via.placeholder.com/1110x100" className="img-fluid full-width" alt="banner" /> <span
                                    className="text">Unlimited Free Delivery with. <img src="https://via.placeholder.com/105x30" alt="logo" /> <a
                                        href="restaurant.html" className="btn-second btn-submit">Try 30 Days FREE</a></span>
                                <span className="close-banner"></span>
                                <div className="overlay overlay-bg"></div>
                            </div>
                        </div>
                    </div>

                </div> */}

                <section className="ex-collection u-line">
                    <div className="container">
                        {/* <div className="row">
                            <div className="col-12">
                                <div className="section-header-left">
                                    <h3 className="text-light-black header-title title">Explore our collections</h3>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="
                        row">
                            <div className="col-md-6">
                                <div className="ex-collection-box mb-xl-20">
                                    <img src="https://via.placeholder.com/540x300" className="img-fluid full-width" alt="image" />
                                    <div className="category-type overlay padding-15"> <a href="restaurant.html" className="category-btn">Top rated</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="ex-collection-box mb-xl-20">
                                    <img src="https://via.placeholder.com/540x300" className="img-fluid full-width" alt="image" />
                                    <div className="category-type overlay padding-15"> <a href="restaurant.html" className="category-btn">Top rated</a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="row">
                            {/* <div className="col-lg-3 col-md-4">
                                <div className="large-product-box mb-xl-20 p-relative">
                                    <img src="https://via.placeholder.com/255x587" className="img-fluid full-width" alt="image" />
                                    <div className="category-type overlay padding-15">
                                        <button className="category-btn">Most popular near you</button> <a href="restaurant.html"
                                            className="btn-first white-btn text-light-black fw-600 full-width">See all</a>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-lg-12 col-md-12 mt-4">
                                <div className="row">
                                    { this.RenderRestaurentList() }

                                </div>
                                <div className="row">
                                    { this.state.RestaurentList.length === 0 &&
                                        <div className="col-lg-12 col-12">
                                            <div className="container-fluid bg-light">
                                                <div className="row justify-content-center align-item-center">
                                                    <img src="../assets/img/svg/notfound.svg" width="30%"></img>
                                                    <h1 className="mb-0 p-3" style={ { color: "rgb(255, 87, 87)" } }>No restaurants found</h1>
                                                </div>
                                            </div>

                                        </div> }
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <Footer></Footer>
            </>
        )
    }
}
