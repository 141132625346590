import React, { Component } from 'react'
import Footer from '../Footer';
import Header from '../Header';
import ApiInstance from '../../Utilities/axiosConfig';

export default class EmailVarification extends Component {
    constructor (props) {
        super(props)
        this.id = this.props.match.params.id;

        this.state = {
            successmsg: "",
            Resposemessage: ''
        }
    }

    componentDidMount() {
        this.GetOrderDetails();
    }

    GetOrderDetails = () => {
        var str = `CustomerMaster/UpdateCustomerEmailVerificationComplete`;
        console.log(str)
        ////const formData = new FormData();
        ApiInstance().post(str, JSON.stringify(this.id), { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                console.log(response.data)
                if (response.data.status) {
                    this.setState({ successmsg: true, Resposemessage: response.data.data })
                }
                else {
                    this.setState({ successmsg: false, Resposemessage: response.data.data })
                }
            }, error => {
                console.log(error);
            });
    }
    render() {
        console.log(this.state)
        return (
            <>
                <div className="main-sec"></div>
                <section className="ex-collection ">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="section-header-left">
                                    { this.state.successmsg === true && <h4 className="text-light-black header-title text-center">{ this.state.Resposemessage } <a href="/Login">Please Sig-in here. </a></h4> }
                                    { this.state.successmsg == false && <h4 className="text-light-black header-title text-center">{ this.state.Resposemessage } </h4> }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}
