import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import { Modal, Accordion, Card } from "react-bootstrap";
import Config from '../../Config'
import ApiInstance from '../../Utilities/axiosConfig';
import { GetUserSearchLocation, SetUserSearchLocation } from '../../GlobalFactory';
import LoaderComponent from '../../Components/LoaderComponent';
import Header from '../Header';
import Footer from '../Footer';
import Swal from 'sweetalert2'

class LoginContent extends Component {

    constructor (props, context) {
        super(props, context);
        this.handleShow1 = this.handleShow1.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            show: false,
            UserName: "",
            Password: "",
            error_description: "",
            isChecked: false,
            ActiveLoader: false
        };
        localStorage.removeItem("tokenData");
        this._handleSubmitEvent = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        //initialize datatable
        function formValidation() {
            window.addEventListener('load', function () {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');

                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function (form) {
                    form.addEventListener('submit', function (event) {
                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form.classList.add('was-validated');
                        }
                        else {
                            form.classList.remove('was-validated');
                        }
                    }, false);
                });

            }, false);


        }
        formValidation();

        // let savedLoginData = JSON.parse(localStorage.getItem("savedLoginData"));
        // if (savedLoginData) {
        //     if (savedLoginData.isChecked) {
        //         this.setState({ UserName: savedLoginData.UserName, Password: savedLoginData.Password, isChecked: savedLoginData.isChecked })
        //         document.getElementById("validationCustom08").value = savedLoginData.UserName;
        //         document.getElementById("validationCustom09").value = savedLoginData.Password;
        //     }
        //     else {
        //         localStorage.removeItem("savedLoginData");
        //     }
        // }

    }

    handleShow1() {
        this.setState({ show1: true });
    }

    handleClose() {
        this.setState({ show1: false });
    }

    handleUserName = event => {
        this.setState({
            UserName: event.target.value
        })

    }

    handlePassword = event => {
        this.setState({
            Password: event.target.value
        })
    }
    onChangeCheckbox = event => {
        this.setState({
            isChecked: event.target.checked
        })
    }

    handleSubmit = event => {

        event.preventDefault()
        if (event.target.checkValidity()) {
            this.setState({ ActiveLoader: true })
            let currentcomponent = this;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();
            urlencoded.append("grant_type", "password");
            urlencoded.append("username", this.state.UserName);
            urlencoded.append("password", this.state.Password);
            urlencoded.append("Scope", 'Customer');
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };



            fetch(Config.apiTokenURL, requestOptions)
                .then(response => response.text())
                .then(result => {

                    var sessionobj = JSON.parse(result);

                    if (sessionobj["error_description"]) {
                        this.setState({ error_description: sessionobj["error_description"] })
                    }
                    else {

                        var savedLoginData = {
                            UserName: this.state.UserName,
                            Password: this.state.Password,
                            isChecked: this.state.isChecked
                        }



                        localStorage.setItem("savedLoginData", JSON.stringify(savedLoginData));
                        localStorage.setItem("tokenData", JSON.stringify(sessionobj))
                        this.GetCustomerProfile(sessionobj.RedirectURL)



                        //window.location.href = '/CustomerProfile';
                    }
                    this.setState({ ActiveLoader: false })
                }
                ).catch(error => console.log('error', error));

        }

    }

    GetCustomerProfile(RedirectURL) {

        ApiInstance().get('CustomerMaster/SelectCustomerProfileAddressByCustomerId')
            .then(response => {
                var data = response;
                if (data) {
                    let RegistrationData = JSON.parse(data.data.data).Data[0]
                    let tblCustomerAddressMasters = RegistrationData.tblCustomerAddressMasters;

                    // let HomeAddress = {};
                    // if (tblCustomerAddressMasters.length > 0) {
                    //     HomeAddress = tblCustomerAddressMasters.find(element => element.AddressName.toLowerCase() == 'home')
                    // }

                    // let OfficeAddress = {};
                    // if (tblCustomerAddressMasters.length > 0) {
                    //     OfficeAddress = tblCustomerAddressMasters.find(element => element.AddressName.toLowerCase() == 'office')
                    // }

                    // let OtherAddress = {}
                    // if (tblCustomerAddressMasters.length > 0) {
                    //     OtherAddress = tblCustomerAddressMasters.find(element => element.AddressName.toLowerCase() == 'other')
                    // }
                    var Address = tblCustomerAddressMasters.filter(item => item.IsDefault == true)
                    var UserSearchLocation = GetUserSearchLocation();

                    if (UserSearchLocation.Address.length < 2) {

                        if (Address.length > 0) {

                            SetUserSearchLocation(Address[0].Latitude, Address[0].Longitude, Address[0].Address1, '')
                        }
                    }


                    localStorage.setItem("userData", JSON.stringify(RegistrationData));
                    console.log(tblCustomerAddressMasters)

                    let url = localStorage.getItem("RedirectURL")
                    if (url) {
                        window.location.href = url;
                        localStorage.setItem("RedirectURL", "")
                    }
                    else {
                        window.location.href = RedirectURL;
                    }
                }
            }, error => {
                console.log(error);
            });
    }

    ResetPassword() {
        Swal.fire({
            title: 'Please enter email id',
            input: 'email',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Submit',
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                ApiInstance().get('CustomerMaster/CustomerforgotPassword?UserName=' + login)
                    .then(response => {
                        console.log(response)
                        if (response.data.status) {
                            Swal.fire('Reset password link has been sent to your register email id!')
                        }
                        else {
                            Swal.fire(response.data.data)
                        }
                        // if (!response.ok) {
                        //     throw new Error(response.statusText)
                        // }
                        return response.data
                    })
                    .catch(error => {
                        console.log(error)
                        Swal.showValidationMessage(
                            `Request failed: ${error}`
                        )
                    });
                // return fetch(`https://localhost:44328/api/CustomerMaster/CustomerforgotPassword/${login}`)
                //     .then(response => {
                //         if (!response.ok) {
                //             throw new Error(response.statusText)
                //         }
                //         return response.json()
                //     })
                //     .catch(error => {
                //         console.log(error)
                //         Swal.showValidationMessage(
                //             `Request failed: ${error}`
                //         )
                //     })

            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            console.log(result)
            // if (result.isConfirmed) {
            //     Swal.fire('Reset password link has been sent to your register email id!')
            // }
            // else {
            //     Swal.fire("Invalid email id!")
            // }
        })
    }
    render() {
        return (
            <>
                <LoaderComponent ActiveLoader={ this.state.ActiveLoader }></LoaderComponent>
                <Header></Header>
                <div className="inner-wrapper">
                    <div className="container-fluid no-padding">
                        <div className="row no-gutters overflow-auto">
                            <div className="col-md-6">
                                <div className="main-banner">
                                    <img src="/assets/img/Signin.jpg" className="img-fluid full-width main-img" alt="banner" />
                                    <div className="overlay-2 main-padding">
                                        {/* <img src="/assets/img/logo_solid.png" className="img-fluid" width="160px" alt="logo" /> */ }
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="section-2 user-page main-padding">
                                    <div className="login-sec">
                                        <div className="login-box">
                                            <form className="needs-validation" noValidate onSubmit={ this._handleSubmitEvent }>
                                                <h4 className="text-light-black fw-600">Sign in with your ChefMeF account</h4>
                                                <div className="row">
                                                    <div className="col-12">
                                                        {/* <p className="text-light-black">Have a corporate username? <a href="http://admin.chefmef.com/Cook">Click here</a>
                                                        </p> */}
                                                        <div className="form-group">
                                                            <label className="text-light-white fs-14">Email/User Name /Phone No.</label>
                                                            <input type="text" name="#" name="UserName" id="UserName" className="form-control form-control-submit" placeholder="Email/User Name /Phone No." required onChange={ this.handleUserName } />
                                                            <div className="invalid-feedback">Please enter email/user name /phone no.</div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="text-light-white fs-14">Password</label>
                                                            <input type="password" id="Password" name="Password" className="form-control form-control-submit" placeholder="Password" required onChange={ this.handlePassword } />
                                                            {/* <div data-name="#password-field" className="fa fa-fw fa-eye field-icon toggle-password"></div> */ }
                                                            <div className="invalid-feedback">Please enter password.</div>
                                                        </div>
                                                        <div className="form-group checkbox-reset">
                                                            <label className="custom-checkbox mb-0">
                                                                <input type="checkbox" name="#" defaultValue onChange={ this.onChangeCheckbox } checked={ this.state.isChecked } /> <span className="checkmark"></span> Remember Password</label> <a href="#" onClick={ () => { this.ResetPassword() } } >Reset password</a>
                                                        </div>
                                                        <div className="form-group">
                                                            <label style={ { color: 'red' } }>{ this.state.error_description }</label>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="submit" className="btn-second btn-submit full-width">
                                                                <img src="assets/img/M.png" alt="btn logo" />Sign in</button>
                                                        </div>
                                                        <div className="form-group text-center"> <span>or</span>
                                                        </div>
                                                        {/* <div className="form-group">
                                                            <button type="submit" className="btn-second btn-facebook full-width">
                                                                <img src="assets/img/facebook-logo.svg" alt="btn logo" />Continue with Facebook</button>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="submit" className="btn-second btn-google full-width">
                                                                <img src="assets/img/google-logo.png" alt="btn logo" />Continue with Google</button>
                                                        </div> */}
                                                        <div className="form-group text-center mb-0"> <a href="/Registration">Create your account</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </>
        );
    }
}

export default LoginContent;