import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ApiInstance from '../../Utilities/axiosConfig';
import { renderIntoDocument } from 'react-dom/test-utils';
import Header from '../Header';
import Footer from '../Footer';
import Swal from 'sweetalert2';
import LoaderComponent from '../../Components/LoaderComponent';

class Defaultregister extends Component {
    constructor () {
        super();

        this.state = {
            RegistrationData: {},
            IsEmailExist: false,
            IsUserNameExist: false,
            IsMobileExist: false,
            TermsAndCondition: true,
            file: '',
            ActiveLoader: false,
        }


        this._handleChangeEvent = this.ItemChangeEvent.bind(this);
        this._checkboxChangeHandler = this.checkboxChangeEvent.bind(this)


    }
    componentDidMount() {
        // //initialize datatable
        function formValidation() {
            window.addEventListener('load', function () {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function (form) {
                    form.addEventListener('submit', function (event) {


                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form.classList.add('was-validated');
                        }
                        else {
                            form.classList.remove('was-validated');
                        }
                    }, false);
                });

            }, false);
        }
        formValidation();
        this._handleSubmitEvent = this.handleSubmit.bind(this);
    }

    ItemChangeEvent = event => {

        const value = event.target.value;
        const key = event.target.name;

        if (key === 'FirstName' || key === 'LastName') {
            var regex = /^[a-zA-Z\s]*$/;
            if (value.match(regex)) {

                let RegistrationData = this.state.RegistrationData;
                RegistrationData[key] = value;

                this.setState({
                    RegistrationData: RegistrationData
                });
            }
        }
        else if (key === 'MobileNo') {
            var regex = /^[0-9]*$/;

            if (value.match(regex)) {
                let RegistrationData = this.state.RegistrationData;
                RegistrationData[key] = value;

                this.setState({
                    RegistrationData: RegistrationData
                });
            }

        }
        else if (key === 'EmailId') {
            this.validateEmail()
            let RegistrationData = this.state.RegistrationData;
            RegistrationData[key] = value;

            this.setState({
                RegistrationData: RegistrationData
            });

        }
        else {
            this.validatePassword()
            let RegistrationData = this.state.RegistrationData;
            RegistrationData[key] = value;

            this.setState({
                RegistrationData: RegistrationData
            });

        }
    }

    resetFormData = () => {

        const clonedState = this.state.RegistrationData;
        const keys = Object.keys(clonedState);
        keys.forEach(key => (delete clonedState[key]));

        this.setState({
            RegistrationData: {},
            IsEmailExist: false,
            IsUserNameExist: false,
            IsMobileExist: false
        });

        var forms = document.getElementsByClassName('needs-validation');
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.classList.remove('was-validated');
        }, false);

    }

    checkboxChangeEvent(event) {

        const value = event.target.checked;
        const key = event.target.name;

        this.setState({
            TermsAndCondition: value
        }, () => { console.log(this.state.TermsAndCondition) });

    }

    validatePassword() {

        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,20})");
        var txtPassword = document.getElementById("Password");

        if (!strongRegex.test(txtPassword.value)) {
            document.getElementById("ValidatePassword").innerHTML = "Password must be contain min 6 and max 20 character with the combination of uppercase ,lower case, numeric and special char";
            return false;
        }
        else {
            document.getElementById("ValidatePassword").innerHTML = "";
            return true;
        }

    }
    validateEmail() {

        var strongRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        var txtEmail = document.getElementById("EmailId");
        if (txtEmail.value === '') {
            document.getElementById("ValidateEmail").innerHTML = "";
            return true;
        }
        else if (!strongRegex.test(txtEmail.value)) {
            document.getElementById("ValidateEmail").innerHTML = "Invalid email address";
            return false;
        }
        else {
            document.getElementById("ValidateEmail").innerHTML = "";
            return true;
        }

    }

    validate(file) {
        console.log(file);

        var valid = true;
        var file_name = "";
        var file_type = "";
        var file_size = "";
        var error_msg = "";
        var valid_size = 5 * 1000 * 1000;
        var display_error = document.getElementById('file_error');

        if (file.files.length != 0) {
            file_name = file.files[0].name;
            file_size = file.files[0].size;
            file_type = file.files[0].type;

            if (file_type != "image/png" && file_type != "image/jpeg" && file_type != "image/pdf" && file_type != "image/bmp") {
                valid = false;
                error_msg = error_msg + "\n* Only 'PNG', 'JPG/JPEG','BMP' and 'PDF' file type supported.";
            }
            if (file_size > valid_size) {
                valid = false;
                error_msg = error_msg + "\n* Filesize should be upto 5MB.";
            }
        }
        else {
            valid = false;
            error_msg = error_msg + "\n* Please select any image file.";
        }
        if (valid == true) {

            /*Write ajax code here to send file to the server.*/
            return true;
        }
        else {
            display_error.innerHTML = error_msg;
            return false;
        }
    }

    handleSubmit = event => {
        event.preventDefault()
        let TermsAndCondition = (this.state.TermsAndCondition === '' || this.state.TermsAndCondition === false) ? false : this.state.TermsAndCondition
        this.setState({
            IsEmailExist: false,
            IsUserNameExist: false,
            IsMobileExist: false,
            TermsAndCondition: TermsAndCondition
        })

        if (event.target.checkValidity() && this.validatePassword() && this.state.TermsAndCondition === true) {
            console.log(JSON.stringify(this.state.RegistrationData))
            this.setState({ ActiveLoader: true })
            const formData = new FormData();
            // formData.append("file", this.state.file);
            formData.append("data", JSON.stringify(this.state.RegistrationData));

            ApiInstance().post("CustomerMaster/InsertCustomer", formData, { headers: { 'Content-Type': 'multipart/form-data', } })
                .then(response => {
                    let data = response.data;
                    console.log(data);
                    if (data.status) {
                        this.sweetalerthtml();
                        this.resetFormData();
                    }
                    else {
                        if (data.data.toLowerCase().includes("username")) {
                            this.setState({ IsUserNameExist: true }, () => { })
                        }

                        if (data.data.toLowerCase().includes("email")) {
                            this.setState({ IsEmailExist: true }, () => { })
                        }

                        if (data.data.toLowerCase().includes("mobileno")) {
                            this.setState({ IsMobileExist: true }, () => { })
                        }

                    }

                    this.setState({ ActiveLoader: false })

                }, error => {

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!'

                    })
                    console.log(error);
                    this.setState({ ActiveLoader: false })
                });



        }
        else {
            console.log(event.target.checkValidity())
        }

    }

    onKeyPress(event) {

        return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122));
    }

    sweetalerthtml() {
        Swal.fire({
            title: '<spam>Congratulation !</spam>',
            icon: '',
            html: 'Thank for registering with us, Please check your inbox for verify email id!',
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            cancelButtonText: 'Close',
            cancelButtonAriaLabel: 'Thumbs down'
        }).then(function (result) {
            if (result.value) {
                window.location.reload();
                //this.resetFormData();
            }
        });

    }

    render() {
        return (
            <>

                <LoaderComponent ActiveLoader={ this.state.ActiveLoader }></LoaderComponent>
                <Header></Header>
                <div className="inner-wrapper">
                    <div className="container-fluid no-padding">
                        <div className="row no-gutters overflow-auto">
                            <div className="col-md-6">
                                <div className="main-banner">
                                    <img src="/assets/img/Signin.jpg" className="img-fluid full-width main-img" alt="banner" />
                                    <div className="overlay-2 main-padding">
                                        {/* <img src="/assets/img/logo_solid.png" className="img-fluid" alt="logo" /> */ }
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="section-2 user-page main-padding">
                                    <div className="login-sec">
                                        <div className="login-box">
                                            <form className="needs-validation" noValidate onSubmit={ this._handleSubmitEvent } id="registrationform">
                                                <h4 className="text-light-black fw-600">Create your account</h4>
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-white fs-14">First name</label>
                                                            <input type="text" name="FirstName" pattern="^[a-zA-Z]+$" id="FirstName" className="form-control form-control-submit" value={ this.state.RegistrationData.FirstName || '' } placeholder="John" required onChange={ this._handleChangeEvent } />
                                                            <div className="invalid-feedback">
                                                                Please enter first name.
                                                             </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-white fs-14">Last name</label>
                                                            <input type="text" id="LastName" name="LastName" pattern="^[a-zA-Z]+$" className="form-control form-control-submit" placeholder="Doe" value={ this.state.RegistrationData.LastName || '' } required onChange={ this._handleChangeEvent } />
                                                            <div className="invalid-feedback">
                                                                Please enter last name.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="text-light-white fs-14">User Name</label>
                                                            <input type="text" minLength="4" maxLength="15" className="form-control" id="UserName" name='UserName' value={ this.state.RegistrationData.UserName || '' } placeholder="JohnDoe" required onChange={ this._handleChangeEvent } pattern="^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,18}[a-zA-Z0-9]$" />
                                                            { !this.state.IsUserNameExist && <div className="invalid-feedback">
                                                                Please enter username.
                                                        </div>
                                                            }
                                                            { this.state.IsUserNameExist && <div className="invalid-feedback d-block">
                                                                User name already exist.
                                                            </div>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="text-light-white fs-14">Email</label>
                                                            <input type="email" className="form-control" id="EmailId" name="EmailId" value={ this.state.RegistrationData.EmailId || '' } placeholder="johndoe@xyz.com" required onChange={ this._handleChangeEvent } />
                                                            <div className="invalid-feedback">
                                                                Email id is required.
                                                          </div>


                                                            { this.state.IsEmailExist && <div className="invalid-feedback d-block">
                                                                Email Id already exist.
                                                         </div> }
                                                            <div className="invalid-feedback d-block" id="ValidateEmail"> </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="text-light-white fs-14">MobileNo</label>
                                                            <input type="text" minLength="10" maxLength="10" pattern="^[0-9]+$" className="form-control form-control-submit" id="MobileNo" value={ this.state.RegistrationData.MobileNo || '' } name="MobileNo" placeholder="999 999 9999" required onChange={ this._handleChangeEvent } />
                                                            { !this.state.IsMobileExist && <div className="invalid-feedback">
                                                                Please enter number.
                                                            </div>
                                                            }
                                                            { this.state.IsMobileExist && <div className="invalid-feedback d-block">
                                                                Mobile Number already exist.
                                                        </div> }
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="text-light-white fs-14">Password (6 character minimum)
                                                                 <span>
                                                                    <i data-toggle="tooltip"
                                                                        title="Password must be contain min 6 and max 20 character with the combination of uppercase ,lower case, numeric and special char"
                                                                        class="fa fa-question-circle"></i></span>

                                                            </label>

                                                            <input type="password" id="Password" name="Password" minLength="6" maxLength="20" className="form-control form-control-submit" placeholder="*******" required onChange={ this._handleChangeEvent } onBlur={ () => this.validatePassword() } title="Password must be contain min 6 and max 20 character with the combination of uppercase ,lower case, numeric and special char" />
                                                            <div className="invalid-feedback">
                                                                Please enter password.
                                                            </div>
                                                            <div className="invalid-feedback d-block" id="ValidatePassword"> </div>
                                                            {/* <div data-name="#password-field" className="fa fa-fw fa-eye field-icon toggle-password"></div> */ }
                                                        </div>
                                                        {/* <div className="form-group checkbox-reset">
                                                            <label className="custom-checkbox mb-0">
                                                                <input type="checkbox" name="#" /> <span className="checkmark"></span> Keep me signed in</label>
                                                        </div> */}
                                                        <div className="form-group">
                                                            <button type="submit" className="btn-second btn-submit full-width">Create your account</button>
                                                        </div>
                                                        {/* <div className="form-group text-center"> <span>or</span>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="submit" className="btn-second btn-facebook full-width">
                                                                <img src="assets/img/facebook-logo.svg" alt="btn logo" />Continue with Facebook</button>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="submit" className="btn-second btn-google full-width">
                                                                <img src="assets/img/google-logo.png" alt="btn logo" />Continue with Google</button>
                                                        </div> */}
                                                        <div className="form-group text-center">
                                                            <p className="text-light-black mb-0">Have an account? <a href="/Login">Sign in</a>
                                                            </p>
                                                        </div> <span className="text-light-black fs-12 terms">By creating your Munchbox account, you agree to the <a href="#"> Terms of Use </a> and <a href="#"> Privacy Policy.</a></span>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer></Footer>
            </>
        );
    }
}

export default Defaultregister;