import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <>
                <div className="footer-top ">
                    <div className="container-fluid p-5">
                        <div className="row">
                            <div className="col-md-2 col-sm-4 col-6 mb-sm-20">
                                <div className="icon-box"> <span className="text-light-green"><i className="flaticon-credit-card-1"></i></span>
                                    <span className="text-light-black">100% Payment<br />Secured</span>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-4 col-6 mb-sm-20">
                                <div className="icon-box"> <span className="text-light-green"><i className="flaticon-wallet-1"></i></span>
                                    <span className="text-light-black">Support lots<br /> of Payments</span>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-4 col-6 mb-sm-20">
                                <div className="icon-box"> <span className="text-light-green"><i className="flaticon-help"></i></span>
                                    <span className="text-light-black">24 hours / 7 days<br />Support</span>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-4 col-6">
                                <div className="icon-box"> <span className="text-light-green"><i className="flaticon-truck"></i></span>
                                    <span className="text-light-black">Free Delivery<br />with $50</span>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-4 col-6">
                                <div className="icon-box"> <span className="text-light-green"><i className="flaticon-guarantee"></i></span>
                                    <span className="text-light-black">Best Price<br />Guaranteed</span>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-4 col-6">
                                <div className="icon-box"> <span className="text-light-green"><i className="flaticon-app-file-symbol"></i></span>
                                    <span className="text-light-black">Mobile Apps<br />Ready</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="bg-light-theme pt-0 u-line">
                    {/* <div className="u-line instagram-slider swiper-container">
                        <ul className="hm-list hm-instagram swiper-wrapper">
                            <li className="swiper-slide">
                                <a href="#"><img src="https://via.placeholder.com/250x200" alt="instagram" /></a>
                            </li>
                            <li className="swiper-slide">
                                <a href="#"><img src="https://via.placeholder.com/250x200" alt="instagram" /></a>
                            </li>
                            <li className="swiper-slide">
                                <a href="#"><img src="https://via.placeholder.com/250x200" alt="instagram" /></a>
                            </li>
                            <li className="swiper-slide">
                                <a href="#"><img src="https://via.placeholder.com/250x200" alt="instagram" /></a>
                            </li>
                            <li className="swiper-slide">
                                <a href="#"><img src="https://via.placeholder.com/250x200" alt="instagram" /></a>
                            </li>
                            <li className="swiper-slide">
                                <a href="#"><img src="https://via.placeholder.com/250x200" alt="instagram" /></a>
                            </li>
                            <li className="swiper-slide">
                                <a href="#"><img src="https://via.placeholder.com/250x200" alt="instagram" /></a>
                            </li>
                            <li className="swiper-slide">
                                <a href="#"><img src="https://via.placeholder.com/250x200" alt="instagram" /></a>
                            </li>
                        </ul>
                    </div> */}
                    <div className="container-fluid">
                        <div className="row">

                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12 text-center mb-3 mt-4">
                                        <img src="/assets/img/logo_solid.png" className="img-fluid" alt="Logo" style={ { maxHeight: "100px" } } />
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-12 text-center form-inline mb-3">
                                        <div className='d-flex justify-content-center w-100'>
                                            <div className="mr-3"><i className="fab fa-facebook-f"></i></div>
                                            <div className="mr-3"><i className="fab fa-twitter"></i></div>
                                            <div><i className="fab fa-instagram"></i></div>
                                        </div>
                                    </div>


                                    <div className="col-lg-12 col-md-12 col-12 text-center form-inline mb-3">
                                        <ul className="d-flex justify-content-center w-100">
                                            <li className="mr-3">Call Us +(347) 123 456 789</li>
                                            <li className="mr-3">|</li>
                                            <li >Email Us support@chefmef.com</li>
                                        </ul>
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-12 text-center form-inline pb-4">
                                        <ul className="d-flex justify-content-center w-100">
                                            <li className="mr-3"><a>Login</a></li>
                                            <li className="mr-3"><a>Create an Account</a></li>
                                            <li className="mr-3"><a>About Us</a></li>
                                            <li>Contact Us</li>

                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </footer>


                {/* <div className="modal fade" id="address-box">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title fw-700">Change Address</h4>
                            </div>
                            <div className="modal-body">
                                <div className="location-picker">
                                    <input type="text" className="form-control" placeholder="Enter a new address" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="search-box">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <div className="search-box p-relative full-width">
                                    <input type="text" className="form-control" placeholder="Pizza, Burger, Chinese" />
                                </div>
                            </div>
                            <div className="modal-body"></div>
                        </div>
                    </div>
                </div> */}

            </>
        )
    }
}
