import React, { Component } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete';
import {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
} from 'react-places-autocomplete';

export default class PlaceAutoComplete extends Component {

    constructor (props) {
        super(props);
        this.state = {
            address: ''
        }
    }

    handleChange = address => {
        this.setState({ address });
    };

    handleSelect = address => {
        console.log(address)
        geocodeByAddress(address)
            .then(results => {
                console.log(results[0]);
                this.props.SetPlace(results[0])
            }).then(latLng => console.log('Success', latLng)).catch(error => console.error('Error', error));
    };
    render() {
        return (
            <>
                <PlacesAutocomplete
                    value={ this.state.address }
                    onChange={ this.handleChange }
                    onSelect={ this.handleSelect }
                >
                    { ({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <input
                                { ...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'form-control',
                                }) }
                            />
                            <div className="autocomplete-dropdown-container">
                                { loading && <div>Loading...</div> }
                                { suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    // const style = suggestion.active
                                    //     ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    //     : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div
                                            { ...getSuggestionItemProps(suggestion, {
                                                className

                                            }) }
                                        >
                                            <span>{ suggestion.description }</span>
                                        </div>
                                    );
                                }) }
                            </div>
                        </div>
                    ) }
                </PlacesAutocomplete>
            </>
        )
    }
}
