import React, { Component } from 'react'
import PlacesAutoComplete from '../Components/PlaceAutoComplete';
import Config from '../Config';
import { GetSessionData, GetUserSearchLocation, SetUserSearchLocation } from '../GlobalFactory';
import HeaderCart from './CheckOut/HeaderCart';


export default class Header extends Component {
    constructor (props) {
        super(props)
        this.state = {
            openSearch: false,
            Locationlabel: 'Brooklyn, NY',
            SearchText: '',
            Radius: 5,
            UserProfile: {}

        }

        this._SearchTextEventHandelere = this.SearchTextEvent.bind(this);
    }

    //////////////////////////////////////////////////////////////////////////////////////////



    //////////////////////////////////////////////////////////////////////////////////////////


    componentDidMount() {

        let UserSearchLocation = GetUserSearchLocation()

        if (String(UserSearchLocation.Latitude).length < 2) {

            var thisprops = this.props;
            var searchstring = this.state.SearchText;
            navigator.geolocation.getCurrentPosition(function (position) {
                console.log("Latitude is :", position.coords.latitude);
                console.log("Longitude is :", position.coords.longitude);
                SetUserSearchLocation(position.coords.latitude, position.coords.longitude, '', 5);
                if (thisprops.GetRestaurentList) thisprops.GetRestaurentList(searchstring);
            });

        }
        else {

            UserSearchLocation = GetUserSearchLocation()
            if (UserSearchLocation.Address.length > 2) {
                let Locationlabel = UserSearchLocation.Address
                this.setState({ Locationlabel: Locationlabel })
            }

            if (UserSearchLocation.Radius > 4) {
                this.setState({ Radius: UserSearchLocation.Radius })
                // document.getElementById("txtRadiusInmiles").value = UserSearchLocation.Radius;
            }

            console.log(UserSearchLocation)
            if (this.props.GetRestaurentList) this.props.GetRestaurentList(this.state.SearchText);
        }


        if ((GetSessionData)) {
            var jsonuserprofile = localStorage.getItem("userData")
            this.setState({ UserProfile: JSON.parse(jsonuserprofile) })
            console.log(JSON.parse(jsonuserprofile))
        }

    }

    toggleClass() {
        const currentState = this.state.openSearch;
        this.setState({ openSearch: !currentState });
    };

    SetCurrentLocation = (place) => {
        console.log(place)
        // let UserSearchLocation = GetUserSearchLocation()
        const currentState = this.state.openSearch;
        SetUserSearchLocation(place.geometry.location.lat(), place.geometry.location.lng(), place.formatted_address, this.state.Radius)
        this.setState({ Locationlabel: place.formatted_address, openSearch: !currentState })

        if (this.props.GetRestaurentList) this.props.GetRestaurentList(this.state.SearchText);
    }

    SetRadius(event) {
        const value = event.target.value;
        const key = event.target.name;
        console.log(value)
        if (value > 4 && value <= 60) {
            SetUserSearchLocation('', '', '', value)

            if (this.props.GetRestaurentList) this.props.GetRestaurentList(this.state.SearchText)
            this.setState({ Radius: value })
        }
    }

    LogOut() {
        localStorage.removeItem("tokenData");
        window.location.href = '/';
    }

    SearchTextEvent(event) {
        const value = event.target.value;
        const key = event.target.name;
        console.log(value)

        this.setState({ SearchText: value })
        console.log('searchtext');
        if (this.props.SearchRestaurentList) this.props.SearchRestaurentList(value)
        if (this.props.FilterDishList) this.props.FilterDishList(value)

    }

    SetCurrentSearcAddress = (value) => {
        console.log(value.formatted_address)
        this.setState({ Locationlabel: value.formatted_address });
    }

    // hideTextbox(event) {
    //     if (event.keyCode === 13) {
    //         const currentState = this.state.openSearch;
    //         if (currentState) {
    //             this.setState({ openSearch: !currentState })
    //         }
    //     }
    // }

    render() {

        let adminBaseURL = (window.location.origin.includes("localhost") || window.location.origin.includes("harshpatel.in")) ? "https://admin.harshpatel.in/" : "https://admin.chefmef.com/";

        return (
            <>
                <div className="header d-lg-nav">
                    <header className="full-width">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 mainNavCol">
                                    {/* logo */ }
                                    <div className="logo mainNavCol">
                                        <a href="/">
                                            {/* <img src="https://via.placeholder.com/106x30" className="img-fluid" alt="Logo" /> */ }
                                            <img src="/assets/img/logo_solid.png" className="img-fluid" alt="Logo" style={ { maxHeight: "40px" } } />

                                        </a>
                                    </div>
                                    {/* logo */ }
                                    <div className="main-search mainNavCol">
                                        <form className="main-search search-form full-width">
                                            <div className="row justify-content-center">
                                                {/* location picker */ }
                                                <div className="col-lg-6 col-md-5">
                                                    <div className="row">

                                                        <div className="col-lg-6">
                                                            <a href="#" className="delivery-add p-relative" style={ { color: "#ff5757" } }>
                                                                <div className="address-overflow">
                                                                    <span className="icon"><i className="fas fa-map-marker-alt" /></span>

                                                                    <a className="dropdown-toggle address-overflow" data-toggle="dropdown">
                                                                        <span className="address ">{ this.state.Locationlabel }</span>
                                                                    </a>
                                                                    <div className="dropdown-menu">
                                                                        <p className="p-3 mb-0">{ this.state.Locationlabel }</p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>

                                                        {/* <div className="col-lg-6">
                                                            <input type="number" id="txtRadiusInmiles" value={ this.state.Radius || 5 } className="form-control" min="0" max="60" style={ { width: "50%" } } name="txtRadiusInmiles" placeholder=" Radius in miles" onChange={ (e) => { this.SetRadius(e) } }></input>
                                                        </div> */}
                                                    </div>



                                                    {/* <div className={ (this.state.openSearch) ? "location-picker open" : "location-picker" }>
                                                        
                                                        <PlacesAutoComplete SetPlace={ this.SetCurrentLocation }></PlacesAutoComplete>
                                                    </div> */}

                                                </div>
                                                {/* location picker */ }
                                                {/* search */ }

                                                <div className="col-lg-6 col-md-7">
                                                    { (this.props.SearchRestaurentList) && <div className="search-box padding-10 autocomplete">
                                                        <input type="text" className="form-control" placeholder="Pizza, Burger, Chinese" value={ this.state.SearchText || '' } id="txtSearch" name="txtSearch" onChange={ this._SearchTextEventHandelere } />
                                                    </div> }
                                                </div>


                                                {/* search */ }
                                            </div>
                                        </form>

                                    </div>
                                    <div className="right-side fw-700 mainNavCol">
                                        {/* <div className="gem-points">
                                            <a href="#"> <i className="fas fa-concierge-bell" />
                                                <span>Order Now</span>
                                            </a>
                                        </div> */}
                                        {/* <div className="catring parent-megamenu">
                                            <a href="#"> <span>Pages <i className="fas fa-caret-down" /></span>
                                                <i className="fas fa-bars" />
                                            </a>
                                            <div className="megamenu">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-5">
                                                                <div className="ex-collection-box h-100">
                                                                    <a href="#">
                                                                        <img src="https://via.placeholder.com/406x271" className="img-fluid full-width h-100" alt="image" />
                                                                    </a>
                                                                    <div className="category-type overlay padding-15"> <a href="restaurant.html" className="category-btn">Top rated</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-7">
                                                                <div className="row">
                                                                    <div className="col-lg-3 col-sm-6">
                                                                        <div className="menu-style">
                                                                            <div className="menu-title">
                                                                                <h6 className="cat-name"><a href="#" className="text-light-black">Home Pages</a></h6>
                                                                            </div>
                                                                            <ul>
                                                                                <li><a href="index.html" className="text-light-white fw-500">Landing Page</a>
                                                                                </li>
                                                                                <li><a href="homepage-1.html" className="text-light-white fw-500">Home Page 1</a>
                                                                                </li>
                                                                                <li className="active"><a href="homepage-2.html" className="text-light-white fw-500">Home Page 2</a>
                                                                                </li>
                                                                                <li><a href="homepage-3.html" className="text-light-white fw-500">Home Page 3</a>
                                                                                </li>
                                                                                <li><a href="homepage-4.html" className="text-light-white fw-500">Home Page 4</a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-sm-6">
                                                                        <div className="menu-style">
                                                                            <div className="menu-title">
                                                                                <h6 className="cat-name"><a href="#" className="text-light-black">Inner Pages</a></h6>
                                                                            </div>
                                                                            <ul>
                                                                                <li><a href="blog.html" className="text-light-white fw-500">Blog Grid View</a>
                                                                                </li>
                                                                                <li><a href="blog-style-2.html" className="text-light-white fw-500">Blog Grid View 2</a>
                                                                                </li>
                                                                                <li><a href="blog-details.html" className="text-light-white fw-500">Blog Details</a>
                                                                                </li>
                                                                                <li><a href="ex-deals.html" className="text-light-white fw-500">Ex Deals</a>
                                                                                </li>
                                                                                <li><a href="about.html" className="text-light-white fw-500">About Us</a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-sm-6">
                                                                        <div className="menu-style">
                                                                            <div className="menu-title">
                                                                                <h6 className="cat-name"><a href="#" className="text-light-black">Related Pages</a></h6>
                                                                            </div>
                                                                            <ul>
                                                                                <li><a href="restaurant.html" className="text-light-white fw-500">Restaurant</a>
                                                                                </li><li><a href="restaurant-style-1.html" className="text-light-white fw-500">Restaurant 1</a>
                                                                                </li>
                                                                                <li><a href="restaurant-style-2.html" className="text-light-white fw-500">Restaurant 2</a>
                                                                                </li>
                                                                                <li><a href="add-restaurant.html" className="text-light-white fw-500">Add Restaurant</a>
                                                                                </li>
                                                                                <li><a href="list-view.html" className="text-light-white fw-500">List View</a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-sm-6">
                                                                        <div className="menu-style">
                                                                            <div className="menu-title">
                                                                                <h6 className="cat-name"><a href="#" className="text-light-black">Additional Pages</a></h6>
                                                                            </div>
                                                                            <ul>
                                                                                <li><a href="login.html" className="text-light-white fw-500">Login</a>
                                                                                </li>
                                                                                <li><a href="register.html" className="text-light-white fw-500">Sign-up</a>
                                                                                </li>
                                                                                <li><a href="checkout.html" className="text-light-white fw-500">Checkout</a>
                                                                                </li>
                                                                                <li><a href="order-details.html" className="text-light-white fw-500">Order Details</a>
                                                                                </li>
                                                                                <li><a href="geo-locator.html" className="text-light-white fw-500">Geo Locator</a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* mobile search */ }
                                        <div className="mobile-search">
                                            <a href="#" data-toggle="modal" data-target="#search-box"> <i className="fas fa-search" />
                                            </a>
                                        </div>
                                        {/* mobile search */ }
                                        { (!GetSessionData()) && <div className="user-details p-relative">
                                            <a href="#" className="text-light-white fw-500">
                                                <span>Sign-Up</span>
                                            </a>
                                            <div className="user-dropdown">
                                                <ul className="d-flex flex-column">
                                                    <li className="w-100">
                                                        {/* <a href="/Registration" className="btn btn-primary">
                                                            <div className="icon"><i className="flaticon-user" />
                                                            </div> <span className="details">Sign up as Customer</span>
                                                        </a> */}
                                                        <a href="/Registration" className="btn btn-theme">
                                                            <div className="icon"><i className="flaticon-user" />
                                                            </div> <span className="details">Sign up as Customer</span>
                                                        </a>
                                                    </li>
                                                    <li className="w-100 mb-0">
                                                        {/* <a href="https://admin.chefmef.com/Cook/default-register">
                                                            <div className="icon"><i className="flaticon-user" />
                                                            </div> <span className="details">Sign up as Cook</span>
                                                        </a> */}

                                                        <a href={ adminBaseURL + "Cook/default-register" } className="btn btn-theme">
                                                            <div className="icon"><i className="flaticon-user" />
                                                            </div> <span className="details">Sign up as Cook</span>
                                                        </a>
                                                    </li>

                                                </ul>

                                            </div>
                                        </div> }

                                        {/* user account */ }
                                        { (GetSessionData())
                                            ?
                                            <div className="user-details p-relative">
                                                <a href="#" className="text-light-white fw-500"><img src={ (this.state.UserProfile.Photo) ? (Config.BaseURL + this.state.UserProfile.Photo) : "https://via.placeholder.com/30" } className="rounded-circle" alt="userimg" style={ { maxWidth: "30px", maxHeight: "40px" } } /> <span>Hi, { GetSessionData().UserName || '' }</span></a>
                                                <div className="user-dropdown">
                                                    <ul>
                                                        <li>
                                                            <a href="/OrderList">
                                                                <div className="icon"><i className="flaticon-rewind" />
                                                                </div> <span className="details">Past Orders</span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <div className="icon"><i className="flaticon-breadbox" />
                                                                </div> <span className="details">Saved</span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/CustomerProfile">
                                                                <div className="icon"><i className="flaticon-user" />
                                                                </div> <span className="details">Account</span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <div className="icon"><i className="flaticon-board-games-with-roles" />
                                                                </div> <span className="details">Help</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="user-footer"> <span className="text-light-black">Not { GetSessionData().UserName || '' }?</span> <a href="#" onClick={ () => {
                                                        this.LogOut();
                                                    } }>Sign Out</a>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div className="user-details p-relative">
                                                    <a href="#" className="text-light-white fw-500">
                                                        <span>Sign-In</span>
                                                    </a>
                                                    <div className="user-dropdown">
                                                        <ul className="d-flex flex-column">
                                                            <li className="w-100">
                                                                {/* <a href="/Login">
                                                                    <div className="icon"><i className="flaticon-user" />
                                                                    </div> <span className="details">Sign In as Customer</span>
                                                                </a> */}
                                                                <a href="/Login" className="btn btn-theme">
                                                                    <div className="icon"><i className="flaticon-user" />
                                                                    </div> <span className="details">Sign In as Customer</span>
                                                                </a>
                                                            </li>
                                                            <li className="w-100 mb-0">
                                                                <a href={ adminBaseURL + "Cook/" } className="btn btn-theme">
                                                                    <div className="icon"><i className="flaticon-user" />
                                                                    </div> <span className="details">Sign In as Cook</span>
                                                                </a>
                                                            </li>

                                                        </ul>

                                                    </div>
                                                </div>
                                            </>
                                            // <a href="/Login" className="text-light-white fw-500">Sign In </a>
                                        }

                                        {/* mobile search */ }
                                        {/* user notification */ }
                                        { (GetSessionData()) &&
                                            <div className="cart-btn notification-btn">
                                                <a href="#" className="text-light-green fw-700"> <i className="fas fa-bell" />
                                                    <span className="user-alert-notification" />
                                                </a>
                                                <div className="notification-dropdown">
                                                    <div className="product-detail">
                                                        <a href="#">
                                                            <div className="img-box">
                                                                <img src="https://via.placeholder.com/50x50" className="rounded" alt="image" />
                                                            </div>
                                                            <div className="product-about">
                                                                <p className="text-light-black">Lil Johnny’s</p>
                                                                <p className="text-light-white">Spicy Maxican Grill</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="rating-box">
                                                        <p className="text-light-black">How was your last order ?.</p> <span className="text-dark-white"><i className="fas fa-star" /></span>
                                                        <span className="text-dark-white"><i className="fas fa-star" /></span>
                                                        <span className="text-dark-white"><i className="fas fa-star" /></span>
                                                        <span className="text-dark-white"><i className="fas fa-star" /></span>
                                                        <span className="text-dark-white"><i className="fas fa-star" /></span>
                                                        <cite className="text-light-white">Ordered 2 days ago</cite>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {/* user notification */ }
                                        {/* user cart */ }
                                        <div className="cart-btn cart-dropdown">
                                            <HeaderCart></HeaderCart>
                                        </div>
                                        {/* user cart */ }
                                    </div>
                                </div>
                                <div className="col-sm-12 mobile-search">
                                    <div className="mobile-address">
                                        <a href="#" className="delivery-add" data-toggle="modal" data-target="#address-box"> <span className="address">Brooklyn, NY</span>
                                        </a>
                                    </div>
                                    <div className="sorting-addressbox"> <span className="full-address text-light-green">Brooklyn, NY 10041</span>
                                        {/* <div className="btns">
                                            <div className="filter-btn">
                                                <button type="button"><i className="fas fa-sliders-h text-light-green fs-18" />
                                                </button> <span className="text-light-green">Sort</span>
                                            </div>
                                            <div className="filter-btn">
                                                <button type="button"><i className="fas fa-filter text-light-green fs-18" />
                                                </button> <span className="text-light-green">Filter</span>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>

                <nav className="navbar navbar-expand-lg navbar-light d-sm-nav">

                    <a className="navbar-brand" href="/">
                        {/* <img src="https://via.placeholder.com/106x30" className="img-fluid" alt="Logo" /> */ }
                        <img src="/assets/img/logo_solid.png" className="img-fluid" alt="Logo" style={ { maxHeight: "40px" } } />
                    </a>

                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav">


                            <li className="nav-item pb-2">

                                <div className="cart-btn cart-dropdown">
                                    <HeaderCart></HeaderCart>
                                </div>

                            </li>

                            <li className="nav-item pb-2">

                                <a className="nav-link" href="#" data-toggle="modal" data-target="#search-box">
                                    <i className="fas fa-search" style={ { color: '#ff5757' } } />  </a>

                            </li>

                            <li className="nav-item pb-2">
                                <a href="/Login" className="d-flex">
                                    <div className="icon"><i className="flaticon-user mr-1" />
                                    </div> <span className="details">Sign In as Customer</span>
                                </a>
                            </li>
                            <li className="nav-item pb-2">
                                <a href={ adminBaseURL + "Cook/" } className="d-flex">
                                    <div className="icon"><i className="flaticon-user mr-1" />
                                    </div> <span className="details">Sign In as Cook</span>
                                </a>
                            </li>
                            <li className="nav-item pb-2">
                                <a className="nav-link" href="/Registration" className="d-flex">
                                    <div className="icon mr-1"><i className="flaticon-user" />
                                    </div> <span className="details">Sign up as Customer</span>
                                </a>
                            </li>

                            <li className="nav-item pb-2">
                                <a className="nav-link" href={ adminBaseURL + "Cook/default-register" } className="d-flex">
                                    <div className="icon mr-1"><i className="flaticon-user" />
                                    </div> <span className="details">Sign up as Cook</span>
                                </a>
                            </li>
                            <li className="nav-item active">
                                <a href="#" className="delivery-add p-relative" style={ { color: "#ff5757" } }>
                                    <div className="address-overflow">
                                        <span className="icon mr-1"><i className="fas fa-map-marker-alt" /></span>


                                        <a className="dropdown-toggle address-overflow" data-toggle="dropdown">
                                            <span className="address ">{ this.state.Locationlabel }</span>

                                        </a>
                                        <div className="dropdown-menu">
                                            <p className="p-3 mb-0">{ this.state.Locationlabel }</p>
                                        </div>
                                    </div>
                                </a>
                            </li>

                        </ul>
                    </div>
                </nav>
            </>
        )
    }
}
