import React, { Component } from 'react'
import { geolocated } from "react-geolocated";
import { Modal, Button } from 'react-bootstrap';
import PlacesAutocomplete from 'react-places-autocomplete';
import PlaceAutoComplete from '../../Components/PlaceAutoComplete'
import { NotificationContainer, NotificationManager } from 'react-notifications';

import {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
} from 'react-places-autocomplete';

import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

export default class Demo extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            btnhandleShow: false

        }
        this.SearchKeyList = []
        this.RestaurentList = [
            {
                "FarAway": 0.05,
                "CookId": 70,
                "FirstName": "Gaurang",
                "LastName": "Patel",
                "BussinessName": "Pizza Huts",
                "ProfilePhoto": "/Resources/CookProfilePhotos/637455514738054588.jpg",
                "CuisineType": "Fast Food,Pizza",
                "Keyword": ", , , , , , , "
            }, {
                "FarAway": 0.05,
                "CookId": 74,
                "FirstName": "xyz",
                "LastName": "xyz",
                "BussinessName": "XYZ1 Pizza Hub",
                "ProfilePhoto": "/Resources/CookProfilePhotos/637453416279446119.jpeg",
                "CuisineType": "American,Italian,Martabak",
                "Keyword": "Italian Pizza,Bakery,Veg,Breakfast,Breads,Nice pizza,Large,Small,, Pasta,Armenian,Veg,Dinner,Breads,Yummy Pasta,half,Full,, Burger,BBQ,Veg,Breakfast,Desserts,Delicious Burger,Full,, , Uttapam,South Indian,Veg,Breakfast,Breads,teasty,Chees Uttapam,Masala Uttapam,"
            }, {
                "FarAway": 0.11,
                "CookId": 73,
                "FirstName": "sneha",
                "LastName": "yadav",
                "BussinessName": "Sneha's Kitchen",
                "ProfilePhoto": "/Resources/CookProfilePhotos/637451489934292436.jpg",
                "CuisineType": "Italian,Roast Chicken,American",
                "Keyword": "Dark Coffee,Coffee,Veg,Breakfast,Tea,dark coffee with malai,Light Dark,Double Dark Coffee,"
            }, {
                "FarAway": 0.35,
                "CookId": 75,
                "FirstName": "xyza",
                "LastName": "xyza",
                "BussinessName": "XYZ2 Punjabi Food",
                "ProfilePhoto": "/Resources/CookProfilePhotos/637453424529972553.jpg",
                "CuisineType": "Biryani,Fast Food,Hyderabadi",
                "Keyword": "Punjabi Food,North Eastern,Veg,Lunch,Entree,Teasty Food,Limited,UnLimited,, South Indian,Fast Food,Veg,Dinner,Beverages,Yummy,Small,Large,"
            }, {
                "FarAway": 1.69,
                "CookId": 78,
                "FirstName": "xyzd",
                "LastName": "xyzd",
                "BussinessName": "XYZ5 South Indian Reasturant",
                "ProfilePhoto": "/Resources/CookProfilePhotos/637454280476924497.jpg",
                "CuisineType": "American,South Indian",
                "Keyword": "Idli,South Indian,Veg,Breakfast,Starters,testy,Half,Full ,, Uttapam,South Indian,Veg,Breakfast,Entree,testy,Tomato,onion uttapam,, Maisur Masala,South Indian,Veg,Breakfast,Starters,testy,Extra Cheese,mysore,"
            }, {
                "FarAway": 2.23,
                "CookId": 79,
                "FirstName": "xyze",
                "LastName": "xyze",
                "BussinessName": "XYZ6 China Town",
                "ProfilePhoto": "/Resources/CookProfilePhotos/637454288206348263.jpg",
                "CuisineType": "Sandwich,American,South American",
                "Keyword": "Manchurian,Fast Food,Veg,Breakfast,Entree,testy,Half,Full,"
            }, {
                "FarAway": 2.61,
                "CookId": 7,
                "FirstName": "HARSH",
                "LastName": "Patel",
                "BussinessName": "shreeji",
                "ProfilePhoto": "/Resources/CookProfilePhotos/637451489934292436.jpg",
                "CuisineType": "South Indian",
                "Keyword": ", , , "
            }, {
                "FarAway": 2.96,
                "CookId": 66,
                "FirstName": "Kavita",
                "LastName": "Sonar",
                "BussinessName": "Legacies Of Punjab",
                "ProfilePhoto": "/Resources/CookProfilePhotos/637451489934292436.jpg",
                "CuisineType": "Healthy Food",
                "Keyword": null
            }, {
                "FarAway": 4.02,
                "CookId": 71,
                "FirstName": "demo",
                "LastName": "Patel",
                "BussinessName": "Domino's Pizza",
                "ProfilePhoto": "/Resources/CookProfilePhotos/637451489934292436.jpg",
                "CuisineType": "Fast Food,Pizza",
                "Keyword": ""
            }, {
                "FarAway": 5.05,
                "CookId": 76,
                "FirstName": "xyzb",
                "LastName": "xyzb",
                "BussinessName": "XYZ3 Dominos Pizza",
                "ProfilePhoto": "/Resources/CookProfilePhotos/637453430051018602.jpg",
                "CuisineType": "African,American,Italian",
                "Keyword": "Vegee Pizza,Pizza,Veg,Dinner,Breads,testy,Small,Medium,Large,, Garlic Bread,Hot Pot,Veg,Breakfast,Breads,yummy,Full,"
            }, {
                "FarAway": 5.29,
                "CookId": 77,
                "FirstName": "xyzc",
                "LastName": "xyzc",
                "BussinessName": "XYZ4 Punjabi Dhaba",
                "ProfilePhoto": "/Resources/CookProfilePhotos/637454272916020017.jpg",
                "CuisineType": "North Indian,American,BBQ",
                "Keyword": "Biriyani,Biryani,Veg,Dinner,Main course,testy,Half,Full,, Paneer Tikka,Healthy Food,Veg,Lunch,Main course,nice,Half,Full,, Tandoori Roti,Thai,Veg,Dinner,Main course,testy,Double,Single,"
            }, {
                "FarAway": 5.75,
                "CookId": 108,
                "FirstName": "jayanti",
                "LastName": "vankar",
                "BussinessName": "Jayanti",
                "ProfilePhoto": "/Resources/CookProfilePhotos/637477366939511134.jpg",
                "CuisineType": "Afghan,Andhra,Asian",
                "Keyword": "Burger,Afghan,Veg,Breakfast,Breads,keet eat within 1 hour,Jumbo,, Brown Burger,Asian,Veg,Breakfast,Beverages,brown bb,"
            }]

    }

    componentDidMount() {
        function autocomplete(inp, arr, RestaurentList) {
            var currentFocus;
            inp.addEventListener("input", function (e) {
                var a, b, c, i, val = this.value;
                closeAllLists();
                if (!val) { return false; }
                currentFocus = -1;
                a = document.createElement("DIV");
                a.setAttribute("id", this.id + "autocomplete-list");
                a.setAttribute("class", "autocomplete-items");
                this.parentNode.appendChild(a);
                for (i = 0; i < arr.length; i++) {
                    if (arr[i]) {
                        if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                            b = document.createElement("DIV");
                            b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                            b.innerHTML += arr[i].substr(val.length);
                            b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                            b.addEventListener("click", function (e) {
                                inp.value = this.getElementsByTagName("input")[0].value;
                                closeAllLists();
                            });
                            a.appendChild(b);
                        }
                    }
                }
                var filterdata = RestaurentList.filter((item) => (item.Keyword) ? item.Keyword.toUpperCase().includes(val.toUpperCase()) : '')
                c = document.createElement("DIV");
                console.log(filterdata)
                var obj = this
                filterdata.map((item) => {
                    c.innerHTML += "<span>" + item.BussinessName + "  <img src=" + item.ProfilePhoto + "/></span><br/>";
                    c.addEventListener("click", function (e) {
                        window.location.href = "/Restaurent/" + item.CookId;
                        closeAllLists();
                    });
                })
                a.appendChild(c);
            });
            /*execute a function presses a key on the keyboard:*/
            inp.addEventListener("keydown", function (e) {
                var x = document.getElementById(this.id + "autocomplete-list");
                if (x) x = x.getElementsByTagName("div");
                if (e.keyCode == 40) {
                    /*If the arrow DOWN key is pressed,
                    increase the currentFocus variable:*/
                    currentFocus++;
                    /*and and make the current item more visible:*/
                    addActive(x);
                } else if (e.keyCode == 38) { //up
                    /*If the arrow UP key is pressed,
                    decrease the currentFocus variable:*/
                    currentFocus--;
                    /*and and make the current item more visible:*/
                    addActive(x);
                } else if (e.keyCode == 13) {
                    /*If the ENTER key is pressed, prevent the form from being submitted,*/
                    e.preventDefault();
                    if (currentFocus > -1) {
                        /*and simulate a click on the "active" item:*/
                        if (x) x[currentFocus].click();
                    }
                }
            });
            function addActive(x) {
                /*a function to classify an item as "active":*/
                if (!x) return false;
                /*start by removing the "active" class on all items:*/
                removeActive(x);
                if (currentFocus >= x.length) currentFocus = 0;
                if (currentFocus < 0) currentFocus = (x.length - 1);
                /*add class "autocomplete-active":*/
                x[currentFocus].classList.add("autocomplete-active");
            }
            function removeActive(x) {
                /*a function to remove the "active" class from all autocomplete items:*/
                for (var i = 0; i < x.length; i++) {
                    x[i].classList.remove("autocomplete-active");
                }
            }
            function closeAllLists(elmnt) {
                /*close all autocomplete lists in the document,
                except the one passed as an argument:*/
                var x = document.getElementsByClassName("autocomplete-items");
                for (var i = 0; i < x.length; i++) {
                    if (elmnt != x[i] && elmnt != inp) {
                        x[i].parentNode.removeChild(x[i]);
                    }
                }
            }
            /*execute a function when someone clicks in the document:*/
            document.addEventListener("click", function (e) {
                closeAllLists(e.target);
            });
        }

        this.searchjson()
        autocomplete(document.getElementById("myInput"), this.SearchKeyList, this.RestaurentList);
    }



    handleShow = (obj) => {
        confirmAlert({ customUI: this.addDialog })

        // this.setState({ btnhandleShow: obj });
    }

    onConfirm() {
        alert("true")
    }

    addDialog = ({ onClose }) => {
        const handleClickedNo = () => {
            alert('clicked no')
            onClose()
        }
        const handleClickedYes = () => {
            alert('clicked yes')
            onClose()
        }
        return (
            <div className='add-dialog'>
                <h3>Add item to display</h3>
                <p>Message......</p>
                <div className="add-dialog-buttons">
                    <button onClick={ handleClickedNo } className="btn" >No</button>
                    <button onClick={ handleClickedYes } className="btn">Yes, add item</button>
                </div>
            </div>
        )
    }

    createNotification = (type) => {
        return () => {
            switch (type) {
                case 'info':

                    NotificationManager.info('Info message');
                    break;
                case 'success':
                    NotificationManager.success('Success message', 'Title here');
                    break;
                case 'warning':
                    NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
                    break;
                case 'error':
                    NotificationManager.error('Error message', 'Click me!', 5000, () => {
                        alert('callback');
                    });
                    break;
            }
        };
    };




    searchjson() {

        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }
        this.RestaurentList.map((item, index) => {
            if (item.Keyword) {
                var array = item.Keyword.split(',');
                array.map((callbackfn) => {
                    if (callbackfn.trim().length > 0) this.SearchKeyList.push(callbackfn.trim())
                });
                this.SearchKeyList = this.SearchKeyList.filter(onlyUnique)
            }

        });
    }
    render() {

        return (
            <>

                <div className="autocomplete" style={ { width: "300px" } }>
                    <input id="myInput" type="text" name="myCountry" placeholder="Country" />
                </div>
                <Button variant="primary" onClick={ () => this.handleShow(true) }>
                    Launch demo modal
                 </Button>

                <Modal show={ this.state.btnhandleShow } onHide={ this.state.btnhandleShow }>
                    <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={ () => this.handleShow(false) }>
                            Close
                        </Button>
                        <Button variant="primary" onClick={ () => this.handleShow(false) }>
                            Save Changes
          </Button>
                    </Modal.Footer>
                </Modal>

                <PlaceAutoComplete></PlaceAutoComplete>

                <div>
                    <button className='btn btn-info'
                        onClick={ this.createNotification('info') }>Info
                      </button>
                    <hr />
                    <button className='btn btn-success'
                        onClick={ this.createNotification('success') }>Success
                     </button>
                    <hr />
                    <button className='btn btn-warning'
                        onClick={ this.createNotification('warning') }>Warning
                     </button>
                    <hr />
                    <button className='btn btn-danger'
                        onClick={ this.createNotification('error') }>Error
                    </button>

                    <NotificationContainer />
                </div>

            </>
        )
    }
}

