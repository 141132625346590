import React, { Component } from 'react'
import { IsUserSignIn } from '../../GlobalFactory';
export default class HeaderCart extends Component {
    constructor (props) {
        super(props);

        this.state = {
            CartOrder: {
                CustomerId: 1,
                CookId: this.id,
                TotalQty: 0,
                TotalAmount: 0,
                PaymnetMode: "cash",
                PaymentStatus: "Success",
                TransactionNumber: "ABCD1234",
                OrderStatusId: 1,
                ExtraNotes: "no onion & garlic",
                tblOrderChilds:
                    [
                        // {
                        //     DishId: "95",
                        //     CookId: "70",
                        //     DishName: "South Inidian",
                        //     CuisineId: "114",
                        //     CuisineName: "Cuisine name",
                        //     FoodTypeId: 164,
                        //     FoodTypeName: "Food Type NAme",
                        //     FoodMealTypeId: 5,
                        //     FoodMealTypeName: "Food meal type name",
                        //     FoodCategoryTypeId: 21,
                        //     FoodCategoryTypeName: "food category id",
                        //     AllergensId: 11,
                        //     AllergensName: "allergence name",
                        //     Photo: "/Resources/CookDishPhotos/70_637406652205624664.jpg",
                        //     TotalCalories: 1500,
                        //     DishVariantId: 161,
                        //     VariantName: "Double",
                        //     AddOnsArray: "[]",
                        //     AddOnsTotal: 10,
                        //     Price: 13,
                        //     Discount: 1,
                        //     PackingCharges: 1,
                        //     FinalPrice: 13.87,
                        //     Quantity: "1",
                        //     QuantityType: "PKG",
                        //     BuyQuantity: 2,
                        //     FinalTotalPerQuantity: 27,
                        //     FinalTotalWithAddOns: 37,
                        // }
                    ]
            }
        }
    }

    // componentDidMount() {
    //     let CartOrder = JSON.parse(localStorage.getItem("CartOrder"));
    //     this.setState({ CartOrder: CartOrder })
    // }

    static getDerivedStateFromProps(props, state) {

        let CartOrder = (localStorage.getItem("CartOrder")) ? JSON.parse(localStorage.getItem("CartOrder")) : state.CartOrder;

        if (CartOrder !== state.CartOrder && CartOrder != null) {
            return {
                CartOrder: CartOrder,
            };
        }
        // Return null to indicate no change to state.
        return null;
    }
    RemoveItem(item) {

        if (window.confirm("Do you want to this remove dish?")) {

            let CartOrder = this.state.CartOrder;
            let tblOrderChilds = CartOrder.tblOrderChilds;
            let TotalAmount = 0;
            let TotalQty = 0

            tblOrderChilds = tblOrderChilds.filter((e) => e.UID !== item.UID)

            tblOrderChilds.map((item, index) => {
                TotalAmount = parseFloat(TotalAmount) + parseFloat(item.FinalTotalWithAddOns);
                TotalQty = parseInt(TotalQty) + parseInt(item.BuyQuantity);
            })
            CartOrder.tblOrderChilds = tblOrderChilds;
            CartOrder.TotalAmount = TotalAmount;
            CartOrder.TotalQty = TotalQty;
            localStorage.setItem("CartOrder", JSON.stringify(CartOrder))
            this.setState({ CartOrder: CartOrder })

        }
    }

    RenderCartBeg() {

        let CartOrder = this.state.CartOrder;
        let tblOrderChilds = this.state.CartOrder.tblOrderChilds;
        return (<>
            {
                tblOrderChilds.map((item, index) =>
                    <div className="cat-product">
                        <div className="cat-name">
                            <a href="#">
                                <p className="text-light-green"><span className="text-dark-white">1</span>{ item.DishName }</p>
                                <span className="text-light-white">{ item.VariantName }</span>
                            </a>
                        </div>
                        <div className="delete-btn">
                            <a href="#" className="text-dark-white" onClick={ (e) => { this.RemoveItem(item) } }> <i className="far fa-trash-alt" />
                            </a>
                        </div>
                        <div className="price">
                            <a href="#" className="text-dark-white fw-500">
                                { item.BuyQuantity }
                            </a>
                        </div>

                        <div className="price">
                            <a href="#" className="text-dark-white fw-500">
                                ${ item.FinalTotalWithAddOns }
                            </a>
                        </div>
                    </div>
                )
            }
        </>
        )
    }

    render() {

        let CartOrder = this.state.CartOrder;


        return (
            <>
                <a href="#" className="text-light-green fw-700">
                    <i className="fas fa-shopping-bag" />
                    <span className="user-alert-cart">{ CartOrder.TotalQty || 0 }</span>
                </a>
                <div className="cart-detail-box">
                    <div className="card">
                        <div className="card-header padding-15">Your Order</div>
                        <div className="card-body no-padding">
                            <div className="cat-product-box">
                                { this.RenderCartBeg() }
                            </div>
                            <div className="item-total">
                                <div className="total-price border-0"> <span className="text-dark-white fw-700">Items subtotal:</span>
                                    <span className="text-dark-white fw-700">${ parseFloat(CartOrder.TotalAmount).toFixed(2) }</span>
                                </div>
                                {/* <div className="empty-bag padding-15"> <a href="#">Empty bag</a>
                                </div> */}
                            </div>
                        </div>
                        { CartOrder.TotalQty > 0 &&
                            <div className="card-footer padding-15"> <a href="#" onClick={ () => { IsUserSignIn() } } className="btn-first green-btn text-custom-white full-width fw-500">Proceed to Checkout</a>
                            </div>
                        }
                    </div>
                </div>

            </>
        )
    }
}
