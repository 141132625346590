
class Config {
  // static apiBaseURL = (window.location.origin.includes("localhost")) ? "http://localhost:21551/api/" : "https://api.chefmef.com/api/";
  // static apiTokenURL = (window.location.origin.includes("localhost")) ? "http://localhost:21551/token" : "https://api.chefmef.com/token";
  // static BaseURL = (window.location.origin.includes("localhost")) ? "http://localhost:21551/" : "https://api.chefmef.com/";

  // static apiBaseURL = (window.location.origin.includes("localhost")) ? "http://localhost:44328/api/" : "https://api.chefmef.com/api/";
  // static apiTokenURL = (window.location.origin.includes("localhost")) ? "http://localhost:44328/token" : "https://api.chefmef.com/token";
  // static BaseURL = (window.location.origin.includes("localhost")) ? "http://localhost:44328/" : "https://api.chefmef.com/";

  static apiBaseURL = (window.location.origin.includes("localhost") || window.location.origin.includes("harshpatel.in")) ? "https://apiv2.harshpatel.in/api/" : "https://api.chefmef.com/api/";
  static apiTokenURL = (window.location.origin.includes("localhost") || window.location.origin.includes("harshpatel.in")) ? "https://apiv2.harshpatel.in/token" : "https://api.chefmef.com/token";
  static BaseURL = (window.location.origin.includes("localhost") || window.location.origin.includes("harshpatel.in")) ? "https://apiv2.harshpatel.in/" : "https://api.chefmef.com/";

  // static apiTokenURL = "http://localhost:21551/token";
  // static apiBaseURL = "http://localhost:21551/api/";
  // static BaseURL = "http://localhost:21551/";

}

export default Config;
